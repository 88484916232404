import React from 'react'
import ReactTooltip from 'react-tooltip'

import { Container, JokerIcon } from './style'

type TypeTooltipComponent = {
  message: string
  label: string
  bold?: boolean
}

export const TooltipComponent = ({
  message,
  label,
  bold
}: TypeTooltipComponent): JSX.Element => {
  return (
    <Container bold={bold}>
      <label htmlFor="form">{label}</label>
      <div>
        <span data-tip={message}>
          <JokerIcon />
        </span>
        <ReactTooltip place="top" type="dark" effect="float" />
      </div>
    </Container>
  )
}
