import { createContext, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface IMenuFoodContext {
  addInstitutionForm: () => void
  removeOneInstitution: (id: string) => void
  selectFormsInstitutions: ISelectFormInstitution[]
  composition: IComposition[]
  setComposition: React.Dispatch<React.SetStateAction<IComposition[]>>
  checkError: (id: string, typeOfError: TTypeOfError) => ICheckedError
  checkActualErrors: () => boolean
  onChangeOfActualErrors: (id: string, typeOfError: TTypeOfError) => void
  updateSelectFormsInstitutions: (idToChange: string, newValue: string) => void
  insertDefaultInstitutions: (menuInstitutions: IMenuInstitution[]) => void
  clearInstitutionsAndItems: () => void
}

export interface ISelectFormInstitution {
  id: string
  institution_id: number
}
export interface IComposition {
  id: string
  dish_id: number
  dish_name: string
  dish_type_id: number
  dish_amount: any
  is_default_dish: boolean
}

export interface IItemError {
  id: string
  error_type: boolean
  error_item: boolean
  error_amount: boolean
  error_item_message?:
    | 'O campo Item é obrigatório.'
    | 'Esse item já foi selecionado.'
  error_is_default_message?:
    | 'O campo Padrão é obrigatório.'
    | 'Já foi selecionado um padrão para esse tipo de item.'
  error_is_default: boolean
}

export interface ICheckedError {
  hasError: boolean
  messageError?: string
}

export type TTypeOfError =
  | 'error_type'
  | 'error_item'
  | 'error_amount'
  | 'error_is_default'

const MenuFoodContext = createContext({} as IMenuFoodContext)

export const MenuFoodContextProvider = ({ children }: IProviderProps) => {
  const INITIAL_ID_FORM = uuidv4()

  const [itemErrors, setItemErrors] = useState<IItemError[]>([])

  const checkError = (id: string, typeOfError: TTypeOfError): ICheckedError => {
    const itemError = itemErrors?.find(itemError => itemError.id === id)
    const messagesErrors = {
      error_amount: 'O campo Quantidade é obrigatório.',
      error_type: 'O Campo Tipo é obrigatório',
      error_item: 'O Campo Item é obrigatório.',
      error_is_default: 'O Campo Padrão é obrigatório'
    }
    const hasErrorObj: ICheckedError = {
      hasError: false,
      messageError: messagesErrors[typeOfError]
    }

    if (itemError) {
      hasErrorObj.hasError = itemError[typeOfError]
      if (typeOfError === 'error_item') {
        hasErrorObj.messageError = itemError.error_item_message
      } else if (typeOfError === 'error_is_default') {
        hasErrorObj.messageError = itemError.error_is_default_message
      }
    }

    return hasErrorObj
  }

  const [selectFormsInstitutions, setSelectFormInstituions] = useState<
    ISelectFormInstitution[]
  >([
    {
      id: INITIAL_ID_FORM,
      institution_id: undefined
    }
  ])

  const [composition, setComposition] = useState<IComposition[]>([
    {
      id: uuidv4(),
      dish_id: undefined,
      dish_type_id: undefined,
      dish_amount: undefined,
      dish_name: undefined,
      is_default_dish: undefined
    }
  ])

  const checkActualErrors = (): boolean => {
    const actualErrors: IItemError[] = []
    let hasError = false
    composition.forEach(({ dish_id, dish_amount, dish_type_id, id }) => {
      const actualError: IItemError = {
        id: '',
        error_type: false,
        error_item: false,
        error_item_message: 'O campo Item é obrigatório.',
        error_amount: false,
        error_is_default: false
      }

      actualError.id = id
      if (dish_type_id === undefined) {
        actualError.error_type = true
      }
      if (dish_id === undefined) {
        actualError.error_item = true
      }
      if (
        dish_amount === undefined ||
        dish_amount === 0 ||
        dish_amount === ''
      ) {
        actualError.error_amount = true
      }

      const itemsWithSameId = composition.filter(
        dish => dish.dish_id === dish_id
      )
      itemsWithSameId.shift()
      if (
        itemsWithSameId.length > 0 &&
        itemsWithSameId.some(item => item.id === id)
      ) {
        actualError.error_item = true
        actualError.error_item_message = 'Esse item já foi selecionado.'
      }
      if (
        actualError.error_amount ||
        actualError.error_item ||
        actualError.error_type
      ) {
        hasError = true
      }
      actualErrors.push(actualError)

      // verificar em cada tipo de item se esse tipo de item tem mais de um default
      const itemsOfEachType: {
        dish_type_id: number
        items: { dish_id: number; id: string; is_default_dish: boolean }[]
      }[] = []

      composition.forEach(({ id, is_default_dish, dish_type_id, dish_id }) => {
        const indexOfDishType = itemsOfEachType.findIndex(
          element => element.dish_type_id === dish_type_id
        )
        const newItem = {
          id,
          dish_id,
          is_default_dish
        }
        if (indexOfDishType === -1) {
          itemsOfEachType.push({
            dish_type_id,
            items: [newItem]
          })
        } else {
          itemsOfEachType[indexOfDishType].items.push(newItem)
        }
      })
      const duplicatedDefaultItems = itemsOfEachType.filter(({ items }) => {
        const itemsCheckedWithDefault = items.filter(
          item => item.is_default_dish
        )

        return itemsCheckedWithDefault.length > 1
      })

      duplicatedDefaultItems.forEach(element => {
        element.items.shift()
      })

      if (
        duplicatedDefaultItems.length > 0 &&
        duplicatedDefaultItems.some(duplicatedDefaultItem =>
          duplicatedDefaultItem.items.some(item => item.id === id)
        )
      ) {
        actualError.error_is_default = true
        actualError.error_is_default_message =
          'Já foi selecionado um padrão para esse tipo de item.'
      }
    })
    setItemErrors(actualErrors)
    console.log(actualErrors)

    return hasError
  }

  const onChangeOfActualErrors = (id: string, typeOfError: TTypeOfError) => {
    const itemErrorIndex = itemErrors?.findIndex(
      itemError => itemError.id === id
    )
    if (itemErrorIndex !== -1) {
      setItemErrors(old => {
        const newItemErrors = [...old]
        newItemErrors[itemErrorIndex][typeOfError] = false
        return newItemErrors
      })
    }
  }

  const addInstitutionForm = () => {
    setSelectFormInstituions(old => [
      ...old,
      { id: uuidv4(), institution_id: undefined }
    ])
  }
  const removeOneInstitution = (idToDelete: string) => {
    setSelectFormInstituions(old => {
      return old.filter(({ id }) => id !== idToDelete)
    })
  }

  const insertDefaultInstitutions = (menuInstitutions: IMenuInstitution[]) => {
    const newSelectForm: ISelectFormInstitution[] = menuInstitutions.map(
      menuInstitution => {
        return {
          institution_id: menuInstitution.institution_id,
          id: uuidv4()
        }
      }
    )
    setSelectFormInstituions(newSelectForm)
  }
  const updateSelectFormsInstitutions = (
    idToChange: string,
    newValue: string
  ) => {
    setSelectFormInstituions(old => {
      const newSelectFormInstitution = [...old]
      const selected = newSelectFormInstitution.find(
        ({ id }) => id === idToChange
      )
      selected.institution_id = +newValue
      return newSelectFormInstitution
    })
  }

  const clearInstitutionsAndItems = () => {
    setComposition([
      {
        id: uuidv4(),
        dish_id: undefined,
        dish_type_id: undefined,
        dish_amount: '',
        dish_name: undefined,
        is_default_dish: undefined
      }
    ])
    setSelectFormInstituions([
      {
        id: INITIAL_ID_FORM,
        institution_id: undefined
      }
    ])
    setItemErrors([])
  }

  return (
    <MenuFoodContext.Provider
      value={{
        composition,
        setComposition,
        addInstitutionForm,
        removeOneInstitution,
        selectFormsInstitutions,
        checkError,
        checkActualErrors,
        onChangeOfActualErrors,
        updateSelectFormsInstitutions,
        insertDefaultInstitutions,
        clearInstitutionsAndItems
      }}
    >
      {children}
    </MenuFoodContext.Provider>
  )
}

export const useMenuFoodContext = () => useContext(MenuFoodContext)
