import { useLoading } from 'hooks/loading'
import { useToast } from 'hooks/toast'
import { useForm } from 'react-hook-form'

interface IChooseItemsForm {
  menuDishes: ImenuDishes[]
  selectedDishes: ISelectedDish[]
  setSelectedDishes: React.Dispatch<React.SetStateAction<ISelectedDish[]>>
  handleClickOnSuccess: (selected: ISelectedDish[]) => void
}

interface ImenuDishes {
  dish_type_id: number
  dish_type_name: string
  dishes: {
    id: number
    dish_amount: number
    name: string
    is_default_dish: boolean
  }[]
}

const ChooseItemsForm = ({
  menuDishes,
  selectedDishes,
  setSelectedDishes,
  handleClickOnSuccess
}: IChooseItemsForm) => {
  // [{dish_type: 'Fruta', dish_type_id:1 dishes: [{name: '', id: , is_default_dish: }] }]
  const { register, handleSubmit } = useForm()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const onSuccessSubmit = async (data: any) => {
    console.log(data)
    const newSelectedDishes = []
    for (const dishTypeName in data) {
      if (data[dishTypeName] === null) {
        addToast({
          type: 'error',
          title: 'Selecione um item para cada categoria'
        })
        return
      }
      const actualDishType = menuDishes.find(
        dishType => dishType.dish_type_name === dishTypeName
      )

      let actualDish
      if (data[dishTypeName]) {
        actualDish = actualDishType.dishes.find(dish => {
          return dish.id === +data[dishTypeName]
        })
      } else {
        actualDish = actualDishType.dishes.find(dish => {
          return dish.is_default_dish
        })
      }

      const selectedDish: ISelectedDish = {
        dish_id: actualDish.id,
        dish_amount: actualDish.dish_amount,
        is_default_dish: actualDish.is_default_dish,
        dish_name: actualDish.name,
        dish_type_name: actualDishType.dish_type_name,
        dish_type_id: actualDishType.dish_type_id
      }
      newSelectedDishes.push(selectedDish)
    }
    setSelectedDishes(newSelectedDishes)
    try {
      activeLoading()
      await handleClickOnSuccess(newSelectedDishes)
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSuccessSubmit)}
        className="d-flex gap-4 flex-column"
      >
        {menuDishes.map(dishByType => {
          return (
            <div
              key={dishByType.dish_type_id}
              className="d-flex gap-2 flex-column"
            >
              <h3 className="fs-5">{dishByType.dish_type_name}</h3>
              {dishByType.dishes.map(dish => {
                return (
                  <div key={dish.id} className="d-flex gap-3">
                    <input
                      id={`dish_${dish.id}`}
                      type="radio"
                      {...register(dishByType.dish_type_name)}
                      name={dishByType.dish_type_name}
                      value={dish.id}
                      defaultChecked={selectedDishes?.some(
                        selectedDish => selectedDish.dish_id === +dish.id
                      )}
                    />
                    <label htmlFor={`dish_${dish.id}`}>
                      {dish.name}
                      {dish.is_default_dish}
                    </label>
                  </div>
                )
              })}
            </div>
          )
        })}
        <button className="btn btn-sm btn-primary float-end">Confirmar</button>
      </form>
    </div>
  )
}

export default ChooseItemsForm
