import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { CSSProperties } from 'styled-components'
import { Container } from './styles'

interface CardSimpleProps {
  primaryColor: string
  classname?: string
  textColor?: string
  text: string
  number: string
  iconSettings?: {
    icon: IconDefinition
    style?: {
      size?: SizeProp
      color?: string
    }
  }
  spanIcon?: string
  style?: CSSProperties
  footer?: {
    link: string
    text: string
    iconSettings?: {
      icon: IconDefinition
      style?: {
        size?: SizeProp
        color?: string
      }
    }
  }
}

export const CardSimple: React.FC<CardSimpleProps> = ({
  primaryColor,
  iconSettings,
  textColor,
  footer,
  style,
  classname,
  number,
  text,
  spanIcon
}) => {
  return (
    <Container
      className={`card p-0 border border-${primaryColor} ${classname || ''}`}
      style={style}
    >
      {/* <div className="card-header"></div> */}
      <div className={`bg-${primaryColor}`}>
        <div className="card-body d-flex justify-content-between">
          <FontAwesomeIcon
            icon={iconSettings?.icon}
            size={iconSettings?.style?.size || '5x'}
            color={iconSettings?.style?.color || '#fff'}
          />
          <div className={`d-flex flex-column text-${textColor || 'white'}`}>
            <span
              className={spanIcon}
              style={{ marginBottom: '10px', fontSize: '18px' }}
            ></span>
            <span className="fw-bolder fs-6">{text}</span>
          </div>
        </div>
      </div>
      <hr />
      <div className="py-4 px-9 d-flex justify-content-between">
        <a href="#" style={{ fontWeight: 'bold', color: '#666' }}>
          {footer?.text}
        </a>
        <FontAwesomeIcon
          icon={footer?.iconSettings?.icon}
          size={footer?.iconSettings?.style?.size || '5x'}
          color={footer?.iconSettings?.style?.color}
          className={`text-${primaryColor}`}
        />
      </div>
    </Container>
  )
}
