import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select as CustomSelect
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import api from 'services/api'
import Select, { MultiValue } from 'react-select'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IMenuFoodType & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormMenuFoodType = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<IMenuFoodType>()
  const [periodsSelected, setPeriodsSelected] = useState<MultiValue<any>>([])
  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const periods = [
    { value: 'Manhã', label: 'Manhã' },
    { value: 'Tarde', label: 'Tarde' },
    { value: 'Integral', label: 'Integral' }
  ]

  const onSubmitForm = async (data: IMenuFoodType) => {
    const id = initialValues?.idUpdate
    data = deleteEmptyFields(data)
    const actualPeriods = periodsSelected.map(period => period.value)
    data.period = JSON.stringify(actualPeriods)

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            handleOnClose()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            updateDataTable()
            handleOnClose()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }

            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response.data, 'here')
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            updateDataTable()
            disableLoading()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          console.log(dataUpdate)
          try {
            activeLoading()
            const res = await api.put(apiUpdate(String(id)), dataUpdate)
            setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            history.push(nameActions.read.to)
            console.log(err.response.data, 'here')

            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              <Input
                name="name"
                className="col-md-4"
                rules={{ required: true }}
                label="Nome"
              />
              {typeForm === 'update' && (
                <CustomSelect
                  className=" col-md-4"
                  name="is_active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  defaultValue={'true'}
                  rules={{ required: true }}
                />
              )}
              <div className="col-md-3 ">
                <label className="col-form-label fw-bold fs-6">Turno</label>
                <Select
                  className="basic-multi-select"
                  isMulti
                  classNamePrefix="select "
                  isSearchable={true}
                  name="period"
                  value={periodsSelected}
                  onChange={e => setPeriodsSelected(e)}
                  options={periods}
                />
              </div>
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
