import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewUpdate } from '../domain/tools'
import { phoneMask } from '../../../../../utlis/mask'
import ViewInfoLabel from 'components/ViewInfoLabel'
import { useAuth } from 'hooks/auth'

const ClientView = (): JSX.Element => {
  const { user } = useAuth()
  const id = user.id
  const history = useHistory()
  const location = useLocation()
  const [client, setClient] = useState<IClientData | null>(null)

  const { addToast } = useToast()
  const { disableLoading, activeLoading } = useLoading()

  async function loadClient(): Promise<void> {
    activeLoading()
    try {
      const response = await api.get<IClientData>(apiList(id))
      const { data } = response
      setClient(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o cliente',
        description:
          'Houve um error ao carregar o cliente, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }
  useEffect(() => {
    loadClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[toolsViewUpdate(String(id))]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel label="Nome" description={client?.name} col={3} />
            <ViewInfoLabel
              label="RG"
              description={client?.identity_card}
              col={3}
            />
            <ViewInfoLabel label="CPF" description={client?.cpf} col={3} />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              label="Data de nascimento"
              description={
                typeof client?.birth_date === 'string' &&
                client?.birth_date.slice(0, 10)
              }
              col={3}
            />
            <ViewInfoLabel
              label="Gênero"
              description={client?.gender}
              col={3}
            />
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <ViewInfoLabel label="CEP" description={client?.zip_code} col={3} />
            <ViewInfoLabel
              label="Logradouro"
              description={client?.street}
              col={3}
            />
            <ViewInfoLabel
              label="Número"
              description={client?.number}
              col={3}
            />
            <ViewInfoLabel
              label="Complemento"
              description={client?.complement}
              col={3}
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              label="Bairro"
              description={client?.district}
              col={3}
            />
            <ViewInfoLabel
              label="Cidade"
              description={`${client?.city} | ${client?.state}`}
              col={3}
            />
          </div>
          <div className="separator my-5" />

          <div className="row mb-5">
            <ViewInfoLabel
              label="Telefone 1"
              description={phoneMask(client?.phone || '')}
              col={3}
            />
            <ViewInfoLabel
              label="Telefone 2"
              description={phoneMask(client?.phone_2 || '')}
              col={3}
            />
            <ViewInfoLabel label="E-mail" description={client?.email} col={3} />
            <ViewInfoLabel
              label="E-mail 2"
              description={client?.email_2}
              col={3}
            />
          </div>
          <div className="separator my-5" />
          <div className="separator my-5" />
          <div className="row mb-5">
            <ViewInfoLabel
              label="Cadastrado em"
              description={client?.created_at}
              col={3}
            />
          </div>
          <div className="separator my-5" />
        </div>
      </Container>
    </>
  )
}

export default ClientView
