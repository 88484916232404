import React, { useCallback, useState, useEffect } from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect
} from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'

import { useAuth } from '../hooks/auth'

import { useToast } from '../hooks/toast'
import { Loading } from '../components/Loading'
import { UnauthenticatedRouteError } from '../components/UnauthenticatedRouteError'
import api from '../services/api'

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType
}

const PrivateRoutes: React.FC<RouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const [loading, setLoading] = useState(false)
  const { userLogged, user, setHistory } = useAuth()
  const { addToast } = useToast()
  const Layout = DefaultLayout

  if (!userLogged()) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          addToast({
            title: 'Pagina não autorizada',
            description:
              'O usuario não tem autorização para acessar esta pagina',
            type: 'error'
          })
          return <Redirect to="/" />
        }}
      />
    )
  }

  if (!loading) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          return (
            <Layout>
              <Component {...rest} />
            </Layout>
          )
        }}
      />
    )
  }
  return <Loading isActive={loading} />
}

export default PrivateRoutes
