export const Menus = [
  {
    id: 1,
    method: 'GET',
    name: 'Tela Inicial',
    url: '/dashboard',
    permission: true,
    icon: 'fa fa-home',
    type: 'front'
  },
  {
    id: 2,
    method: 'GET',
    name: 'Meus dados',
    url: '/profile',
    permission: true,
    icon: 'fa fa-user',
    type: 'front'
  },
  {
    id: 3,
    method: 'GET',
    name: 'Alunos',
    url: '/commercial/students',
    permission: true,
    icon: 'fa fa-users',
    type: 'front'
  },
  {
    id: 4,
    method: 'GET',
    name: 'Contratações',
    url: '/commercial/contracts',
    icon: 'fa fa-handshake',
    permission: true,
    type: 'front'
  },
  {
    id: 5,
    method: 'GET',
    name: 'Cardápios',
    url: '/commercial/menuFoods',
    icon: 'fa fas fa-cookie',
    permission: true,
    type: 'front'
  }
]
