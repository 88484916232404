import React from 'react'
import { FormMenu } from '../components/Form'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsCreate } from '../domain/tools/create'
import Container from '../../../components/Container'

const MenuCreate = (): JSX.Element => {
  return (
    <Container
      pageTitle={nameActions.create.name}
      breadcrumb={breadcrumbCreate}
      tools={toolsCreate}
    >
      <FormMenu typeForm="create" />
    </Container>
  )
}
export default MenuCreate
