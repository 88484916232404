import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface IContainerProps {
  error?: boolean
}

export const Container = styled.div<IContainerProps>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
    color: ${({ error }) => error && '#c53030'} !important;
  }

  & > div {
    position: relative;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }

  input {
    flex: 1;
    background: transparent;
    border: 1px solid #c2cad8;
    padding-right: 30px;
    border-color: ${({ error }) => error && '#c53030'} !important;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  width: fit-content;
  position: absolute;
  right: ${props => (props.type ? '40px' : '5px')};
  top: 50%;
  width: 20px;
  margin: 0;
  transform: translateY(-50%);
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    transform: translateX(-50%);
    &::before {
      border-color: #c53030 transparent;
    }
    ${props =>
      props.position === 'left' &&
      css`
        transform: translateX(-90%);
        &::before {
          left: 90%;
        }
      `}
  }
`
export const EndMessage = styled.span`
  height: 20px;
  width: fit-content;
  position: absolute;
  right: 1rem;
  top: 50%;
  font-size: 1.2rem;
  margin: 0;
  transform: translateY(-50%);
  svg {
    margin: 0;
  }
`

export const SelectContainer = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
    display: flex;
    gap: 10px;
    color: ${props => (props.erro ? 'red!important' : '#3F4254!important')};
  }

  .select-div {
    position: relative;
  }
  select {
    width: 100%;

    border: ${props => props.erro && '1px solid red!important'};
    color: ${props => (props.erro ? 'red!important' : '#5E6278!important')};

    option:disabled {
      display: none;
    }
  }
`

export const TextAreaContainer = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;
  position: relative;

  > div > div {
    right: 1.5rem;
  }

  > label {
    font-weight: normal;
    color: ${props => props.erro && 'rgb(197, 48, 48)'} !important;
  }

  textarea {
    width: 100%;
    padding: 5px;
    border: ${props => props.erro && '1px solid rgb(197, 48, 48)'} !important;

    option:disabled {
      display: none;
    }
  }
`
export const DatePickerContainer = styled.div<{ error?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
    color: ${props => props.error && 'rgb(197, 48, 48)'};
  }

  & > div {
    position: relative;
  }

  .react-datepicker,
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 12px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 7px;
  }
`

export const DateContainer = styled.div<{ error?: boolean }>`
  border-radius: 0.475rem;
  border: ${props => props.error && '1px solid rgb(197, 48, 48)'};
`
