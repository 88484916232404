import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import CustomDataTable from '../../../../components/CustomDataTable'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { useAuth } from 'hooks/auth'
import moment from 'moment'
import { useLoading } from 'hooks/loading'

const ContractList = (): JSX.Element => {
  const [contracts, setContracts] = useState<IContract[]>([])
  const { user } = useAuth()
  const { activeLoading, disableLoading } = useLoading()
  const getContracts = useCallback(async () => {
    activeLoading()
    const contractsListResponse = await api.get(
      `commercial/contracts/byClient/${user.id}`
    )
    const contractsSort = contractsListResponse.data.sort(
      (
        currentContract: { created_at: string },
        nextContract: { created_at: string }
      ) =>
        moment(nextContract.created_at, 'DD/MM/YYYY hh:mm:ss').diff(
          moment(currentContract.created_at, 'DD/MM/YYYY hh:mm:ss')
        )
    )
    setContracts(contractsSort)
    disableLoading()
  }, [activeLoading, disableLoading, user.id])

  useEffect(() => {
    getContracts()
  }, [getContracts])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <h1>Minhas contratações</h1>
      <hr style={{ margin: '20px 0px' }} />
      <CustomDataTable
        customItems={contracts}
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/commercial/contracts/view/${item.id}`
          }
        ]}
      />
    </Container>
  )
}

export default ContractList
