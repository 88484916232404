import MenuFoodTypesList from '../../pages/Commercial/MenuFoods/MenuFoodTypes/List'
import MenuFoodTypesCreate from '../../pages/Commercial/MenuFoods/MenuFoodTypes/Create'
import MenuFoodTypesView from '../../pages/Commercial/MenuFoods/MenuFoodTypes/View'
import MenuFoodTypesUpdate from '../../pages/Commercial/MenuFoods/MenuFoodTypes/Update'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const MenuFoodTypesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/commercial/MenuFoodTypes"
      exact
      component={MenuFoodTypesList}
    />
    <Route
      path="/commercial/MenuFoodTypes/create"
      component={MenuFoodTypesCreate}
      exact
    />
    <Route
      path="/commercial/MenuFoodTypes/view/:id"
      component={MenuFoodTypesView}
      exact
    />
    <Route
      path="/commercial/MenuFoodTypes/update/:id"
      component={MenuFoodTypesUpdate}
      exact
    />
  </CustomSwitch>
)
