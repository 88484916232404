import React from 'react'

interface IViewInfoLabel {
  col?: number
  label: string
  description?: string | number
  descriptionWithLink?: JSX.Element
}
const ViewInfoLabel = ({
  col,
  description,
  label,
  descriptionWithLink
}: IViewInfoLabel) => {
  return (
    <div className={`form-group col-md-${col || 3}`}>
      <label className="col-form-label fw-bold form-label fw-bold fs-6">
        {label}
      </label>
      <p className="fw-bolder fs-6 text-gray-800">
        {descriptionWithLink || description}
      </p>
    </div>
  )
}

export default ViewInfoLabel
