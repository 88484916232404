import { useEffect, useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Input, Select } from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiListInstitution, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer, IconRemove } from './styles'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { GENDERS, SCHOOL_PERIODS } from 'commons/constants/commercial'
import { cpfMask } from '../../../../../../utlis/mask'
import { validate } from 'gerador-validador-cpf'
import moment from 'moment'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IStudent & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  clientInfo: {
    id: number
    name: string
  }
  updateOnSubmit: () => void
}

export const FormStudent = ({
  isOpenInModal,
  initialValues,
  typeForm,
  updateOnSubmit,
  clientInfo
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [institutions, setInstitutions] = useState<IInstitutionData[]>([])
  const [defaultValues, setDefaultValues] = useState<IStudent>()
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [birthDate, setBirthDate] = useState<Date | string>()
  const [cpf, setCpf] = useState<string>()
  const [cpfError, setCpfError] = useState<{
    message?: string
    error: boolean
  }>({ message: '', error: false })

  const [studentFoodRestrictions, setStudentFoodRestrictions] = useState<
    Omit<IStudentFoodRestriction, 'created_at' | 'updated_at'>[]
  >([
    {
      id: 0,
      description: '',
      student_id: undefined
    }
  ])

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res =>
        setInstitutions(
          res.data.sort((a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
          )
        )
      )
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  const addStudentFoodRestriction = (student_id: number) => {
    setStudentFoodRestrictions(old => [
      ...old,
      {
        id: old[old.length - 1]?.id + 1 || 1,
        description: '',
        student_id
      }
    ])
  }
  const removeOneStudentFoodRestriction = (idToDelete: number) => {
    setStudentFoodRestrictions(old => {
      return old.filter(({ id }) => id !== idToDelete)
    })
  }

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      if (initialValues?.birth_date) {
        // moment to date ver tipos de data
        initialValues.birth_date = moment(
          initialValues?.birth_date,
          'DD/MM/YYYY'
        ).toDate()
      }
      setStudentFoodRestrictions(initialValues.student_food_restrictions)
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  const checkCpf = (cpf: string) => {
    setCpf(cpfMask(cpf))
    if (cpf.length === 14) {
      if (validate(cpf)) {
        setCpfError({
          error: false,
          message: 'CPF válido'
        })
      } else {
        setCpfError({
          error: true,
          message: 'CPF inválido'
        })
      }
    }
  }

  const { activeLoading, disableLoading } = useLoading()
  const onSubmitForm = async (dataForm: IStudent) => {
    if (cpfError.error) {
      window.scrollTo(0, 0)
      return
    }
    const data = deleteEmptyFields(dataForm)
    data.client_id = clientInfo.id
    // remove restrictionFood input names
    for (const key in data) {
      if (key.includes('input_food_restriction')) {
        delete data[key]
      }
    }

    data.student_food_restrictions = studentFoodRestrictions.map(
      ({ description }) => ({
        description
      })
    )

    const id = initialValues?.idUpdate
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()

          try {
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            updateOnSubmit()
            handleOnClose()
            disableLoading()
            setHasUpdated(true)

            addToast({
              type: 'success',
              title: 'Estudante criado',
              description: 'Estudante criado com sucesso'
            })
          } catch (error: any) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o estudante',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            console.log(error.response.data)

            handleOnClose()
            disableLoading()
            updateDataTable()
            updateOnSubmit()

            setHasUpdated(true)
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            updateOnSubmit()

            disableLoading()
            addToast({
              type: 'success',
              title: 'Estudante criado',
              description: 'Estudante criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response)
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o estudante',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            updateOnSubmit()

            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Estudante atualizado',
              description: 'Estudante alterado com sucesso'
            })
          } catch (error: any) {
            console.log(error.response.data)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o estudante',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Estudante atualizado',
              description: 'Estudante alterado com sucesso'
            })
          } catch (err: any) {
            console.log(err.response.data)

            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o estudante',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  const actualSeriesOptions = institutions.find(
    ({ id }) => initialValues.institution_id === id
  )?.series_classes

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Input
                name="name"
                className=" col-md-6"
                label="Nome"
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="cpf"
                className=" col-md-2"
                label="CPF"
                value={cpf}
                rules={{ required: true, position: 'left' }}
                maxLength={14}
                onChange={event => checkCpf(event.target.value)}
                hasError={{
                  message: cpfError.message,
                  error: cpfError.error
                }}
              />
            </div>
            <div className="row mb-5">
              <Select
                name="gender"
                className="col-md-3"
                label="Sexo"
                rules={{ required: true }}
                options={GENDERS}
                blank
              />
              <DatePicker
                label="Data de nascimento"
                className="col-md-3"
                name="birth_date"
                rules={{ required: true }}
                value={birthDate as string}
                selected={
                  (birthDate as Date) ||
                  (defaultValues?.birth_date as Date) ||
                  undefined
                }
                onChange={date => setBirthDate(date)}
                maxDate={new Date()}
                refuseDateAfterToday
                calendarContainer={() => (
                  <div style={{ display: 'none' }}></div>
                )}
                controlled
              />
              <Select
                name="school_period"
                className="col-md-3"
                label="Turno"
                rules={{ required: true }}
                options={SCHOOL_PERIODS}
                blank
              />
              <Select
                name="serie_class_id"
                className="col-md-3"
                label="Ano/Turma"
                rules={{ required: true }}
                options={
                  actualSeriesOptions?.map(isc => ({
                    name: isc.name,
                    value: Number(isc.id)
                  })) || []
                }
                blank
              />
            </div>
            <div>
              <div style={{ margin: '00 -1rem' }}>
                <h3
                  className="col-form-label fw-bold fs-6 bg-secondary"
                  style={{ padding: '0.7rem 1rem' }}
                >
                  Restrições alimentares
                </h3>
              </div>
              {studentFoodRestrictions?.map((foodRestriction, index) => (
                <div className="row" key={foodRestriction.id}>
                  <Input
                    defaultValue={foodRestriction.description}
                    name={`input_food_restriction_${foodRestriction.id}`}
                    label="Restrição alimentar"
                    className="col-11"
                    value={studentFoodRestrictions[index].description}
                    onChange={event => {
                      setStudentFoodRestrictions(old => {
                        const newFoodRestrictions = [...old]
                        newFoodRestrictions[index].description =
                          event.target.value
                        return newFoodRestrictions
                      })
                    }}
                  />
                  <div className="col-1 align-self-center pt-7 ">
                    <IconRemove
                      onClick={() =>
                        removeOneStudentFoodRestriction(foodRestriction.id)
                      }
                    />
                  </div>
                </div>
              ))}

              <div className="separator my-5" />
              {console.log(initialValues?.id)}
              <button
                type="button"
                className="btn btn-dark btn-sm mb-5"
                onClick={() => addStudentFoodRestriction(initialValues?.id)}
              >
                + Restrição alimentar
              </button>
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
