/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { cnpjMask, phoneMask } from '../../../../utlis/mask'
import { headersMenuQueryApi, headersStudentQueryApi } from '../domain/headers'
import ViewInfoLabel from 'components/ViewInfoLabel'

const InstitutionView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [institution, setInstitution] = useState<IInstitutionData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const searchParams = useRef([{ institution_id: `${id}` }])
  const searchParametersAuditLog = useRef([
    { entity: 'Institution', entity_id: id }
  ])

  const { disableLoading, activeLoading } = useLoading()
  useEffect(() => {
    async function loadInstitution(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IInstitutionData>(apiList(id))
        const { data } = response
        setInstitution(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a instituição',
          description:
            'Houve um error ao carregar a instituição, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadInstitution()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Instituição removida com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover a instituição, pois ela está registrada num contrato.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Instituição removida com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover essa instituição, pois ela está registrada num contrato.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel col={3} description={institution?.id} label="Cód." />
            <ViewInfoLabel
              col={3}
              description={institution?.company_name}
              label="Razão Social"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.company_social_name}
              label="Nome Fantasia"
            />
            <ViewInfoLabel
              col={3}
              description={cnpjMask(institution?.cnpj)}
              label="CNPJ"
            />
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={institution?.zip_code}
              label="CEP"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.street}
              label="Logradouro"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.number}
              label="Número"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.complement}
              label="Complemento"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={institution?.district}
              label="Bairro"
            />
            <ViewInfoLabel
              col={3}
              description={`${institution?.city} | ${institution?.state}`}
              label="Cidade"
            />
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={phoneMask(institution?.phone || '')}
              label="Telefone 1"
            />
            <ViewInfoLabel
              col={3}
              description={phoneMask(institution?.phone_2 || '')}
              label="Telefone 2"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.email}
              label="E-mail"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.email_2}
              label="E-mail 2"
            />
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={institution?.has_comission === true ? 'Sim' : 'Não'}
              label="Comissão"
            />
            <ViewInfoLabel
              col={3}
              description={`${institution?.percentage_comission} %`}
              label="Porcentagem da comissão"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.is_active === true ? 'Sim' : 'Não'}
              label="Ativo"
            />
            <ViewInfoLabel
              col={3}
              description={institution?.created_at}
              label="Cadastrado em"
            />
          </div>
          <div className="separator my-5" />
          <div className="row">
            <ViewInfoLabel
              col={3}
              description={institution?.observation}
              label="Observações"
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab key={0} title="Estudantes">
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        format={{ orderBy: 'name' }}
                        source="commercial/students"
                        entity="Student"
                        onlyView
                        headers={headersStudentQueryApi}
                        searchParameters={searchParams.current}
                        actions={[
                          {
                            name: 'view',
                            title: 'Visualizar',
                            spanIcon: 'fa fa-search',
                            linkTo: student =>
                              `/commercial/students/view/${student.id}`,
                            style: { margin: '0 5px' }
                          }
                        ]}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab key={1} title="Cardápios">
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        format={{ orderBy: 'id' }}
                        source="commercial/menuFoods"
                        entity="MenuFoodInstitution"
                        onlyView
                        headers={headersMenuQueryApi}
                        searchParameters={searchParams.current}
                        actions={[
                          {
                            name: 'view',
                            title: 'Visualizar',
                            spanIcon: 'fa fa-search',
                            linkTo: menu =>
                              `/commercial/menuFoods/view/${menu.id}`,
                            style: { margin: '0 5px' }
                          }
                        ]}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab title="Histórico" key={2}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${institution?.company_social_name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(institution?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default InstitutionView
