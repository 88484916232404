import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { FilterContaier } from './styles'
import api from '../../../../services/api'
import { filterDuplicatedsObject } from '../../../../utlis/filterDuplicatesObject'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'

type PermissionData = {
  name: string
  grouped?: string
  function?: string
  id: number
}

const List: React.FC = () => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const [valueGrouped, setValueGrouped] = useState<string>('')
  const [valueFunction, setValueFunction] = useState<string>('')
  const [permissionsGrouped, setPermissionsGrouped] = useState<
    PermissionData[]
  >([])
  const [permissions, setPermissions] = useState<PermissionData[]>([])
  const [permissionsFunction, setPermissionsFunction] = useState<
    PermissionData[]
  >([])
  const [parameters, setParameters] = useState<any>()

  const loadPermissions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/users/permissions')
      setPermissionsGrouped(filterDuplicatedsObject(data, 'grouped'))
      setPermissions(data)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar as permissões',
        description:
          'Ocorreu um erro ao carregar as permissões, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadPermissions()
  }, [loadPermissions])

  const handleSelectFilter = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, fieldFilter: string) => {
      const filterPermissions = permissions.filter((permission: any) => {
        return (
          permission[fieldFilter]?.toLowerCase() ===
          event.target.value.toLowerCase()
        )
      })
      return filterPermissions
    },
    [permissions]
  )
  return (
    <Container
      pageTitle={namePageTitle}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <FilterContaier>
        <div>
          <div className="col-md-3">
            <label htmlFor="grouped">Grupo</label>
            <select
              className="form-control"
              id="grouped"
              value={valueGrouped}
              onChange={event => {
                setValueGrouped(event.target.value)
                setPermissionsFunction(
                  filterDuplicatedsObject(
                    handleSelectFilter(event, 'grouped'),
                    'function'
                  )
                )
              }}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              {permissionsGrouped?.map(({ id, grouped }) => (
                <option key={id} value={grouped}>
                  {grouped}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="function">Função</label>
            <select
              className="form-control"
              id="function"
              value={valueFunction}
              onChange={({ target }) => setValueFunction(target.value)}
              disabled={!permissionsFunction.length}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              {permissionsFunction?.map(
                ({ id, function: permissionFunction }) => (
                  <option key={id} value={permissionFunction}>
                    {permissionFunction}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
        <footer>
          <button
            onClick={() => {
              setValueFunction('')
              setValueGrouped('')
              setParameters([])
            }}
          >
            LIMPAR
          </button>
          <button
            onClick={() => {
              setParameters([
                {
                  function: valueFunction || undefined,
                  grouped: valueGrouped || undefined
                }
              ])
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr className="divider" />
      </FilterContaier>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        notHasChildren
        headers={headers}
        searchParameters={parameters}
      />
    </Container>
  )
}
export default List
