import { LinkContainer } from 'components/DataTable/style'
import Tooltip from 'components/Tooltip'
import { MenuTableRow } from 'pages/Menus/View/styles'
import { useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { calculateDiscountToBRL } from 'utlis/calculateDiscount'
import { currencyMask } from 'utlis/mask'
import InfoLabel from '../InfoLabel'
import { CardSubText, CardSummary, SummarySubText } from '../Summary/styles'

interface IInfoContract {
  studentData: IStudent
  contractPlans: IContractPlanDisplay[]
  createdFinancialMoviments: IFinancialMoviment[]
  subTotal: string
  total: string
  discount: string
  getPdfBoleto: (financialMovimentPaymentId: number, barCode: string) => void
  previewContract: () => void
  confirmContract: () => void
  handleClickOnOpenModalDescription: (menuFood: IMenuFood) => void
  handleClickOnOpenModalTableWithItems: (actualDishes: ISelectedDish[]) => void
}

export const InfoContract = ({
  studentData,
  contractPlans,
  createdFinancialMoviments,
  discount,
  subTotal,
  total,
  getPdfBoleto,
  previewContract,
  confirmContract,
  handleClickOnOpenModalDescription,
  handleClickOnOpenModalTableWithItems
}: IInfoContract) => {
  const [contractAccepted, setContractAccepted] = useState(false)
  let studentRestrictions = ''
  studentData.student_food_restrictions.forEach(
    ({ description }) => (studentRestrictions += description)
  )
  return (
    <div>
      <div className="mb-5">
        <h3 className="col-form-label fw-bold fs-5">Informações do cliente</h3>
        <div className="row">
          <InfoLabel
            title="Nome"
            description={studentData.client.name}
            colunm={3}
          />
          <InfoLabel
            title="CPF"
            description={studentData.client.cpf}
            colunm={3}
          />
          <InfoLabel
            title="E-mail"
            description={studentData.client.email}
            colunm={3}
          />
          <InfoLabel
            title="Telefone"
            description={studentData.client.phone}
            colunm={3}
          />
        </div>
      </div>
      <div className="separator my-5" />
      <div className="mb-5">
        <h3 className="col-form-label fw-bold fs-5">
          Informações do estudante
        </h3>
        <div className="row">
          <InfoLabel title="Nome" description={studentData.name} colunm={3} />
          <InfoLabel
            title="Turno"
            description={studentData.school_period}
            colunm={3}
          />
          <InfoLabel
            title="Sala"
            description={studentData.school_room}
            colunm={3}
          />
          <InfoLabel
            title="Ano escolar"
            description={studentData.school_year}
            colunm={3}
          />
          <InfoLabel
            title="Restrições alimentares"
            description={studentRestrictions}
            colunm={12}
          />
        </div>
      </div>
      <div className="separator my-5" />
      <div>
        <h3 className="col-form-label fw-bold form-label fw-bold fs-5">
          Planos contratados
        </h3>
        <div className="row mb-5 gap-4">
          {contractPlans.map(contract => {
            return (
              <CardSummary key={contract.plan_id} className="col-md-3">
                <div className="d-flex justify-content-between gap-4">
                  <h3 style={{ color: '#3F4254' }}>{contract.plan_name}</h3>
                  <CardSubText>{contract.periodicity_payament}</CardSubText>
                </div>

                <p className="d-flex justify-content-between mb-2">
                  <CardSubText
                    className="fs-7 text-primary"
                    onClick={() =>
                      handleClickOnOpenModalDescription(contract.menu_food)
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    Detalhes do cardápio
                  </CardSubText>
                  {contract.items_selected && (
                    <CardSubText
                      className="fs-7 text-primary"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const selectedDishes = JSON.parse(
                          contract.items_selected
                        )
                        handleClickOnOpenModalTableWithItems(selectedDishes)
                      }}
                    >
                      Itens selecionados
                    </CardSubText>
                  )}
                </p>
                <p className="d-flex justify-content-between mb-2">
                  <SummarySubText>Valor do plano</SummarySubText>{' '}
                  <SummarySubText>
                    {currencyMask(contract.plan_value)}
                  </SummarySubText>
                </p>
                <p className="d-flex justify-content-between mb-2">
                  <SummarySubText>Desconto</SummarySubText>{' '}
                  <SummarySubText>
                    {calculateDiscountToBRL(
                      contract.discount.toString(),
                      +contract.plan_value
                    )}
                  </SummarySubText>
                </p>
                <p className="d-flex justify-content-between">
                  <SummarySubText fontSize={13} color={'#464E5F'}>
                    Total
                  </SummarySubText>{' '}
                  <SummarySubText fontSize={13} color={'#464E5F'}>
                    {currencyMask(contract.value_with_discount)}
                  </SummarySubText>
                </p>
              </CardSummary>
            )
          })}
        </div>
      </div>
      <div className="separator my-5" />
      <div>
        <h3 className="col-form-label fw-bold form-label fw-bold fs-5">
          Informações do pagamento
        </h3>
        <div className="row">
          <InfoLabel title="Subtotal" description={subTotal} colunm={3} />
          <InfoLabel title="Desconto" description={discount} colunm={3} />
          <InfoLabel title="Total" description={total} colunm={3} />
        </div>
      </div>
      <div className="separator my-5" />

      <div className="d-flex gap-5 align-items-center">
        <h3 className="col-form-label fw-bold form-label fw-bold fs-5">
          Finalização da contratação
        </h3>
        <div className="h-100 d-flex align-items-center w-20">
          <Tooltip
            title="Após a confirmação, o sistema gerará o boleto do primeiro pagamento"
            widthContainer="auto"
            width={500}
          >
            <AiOutlineQuestionCircle
              fontSize={18}
              style={{ position: 'initial' }}
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => previewContract()}
        >
          Visualizar contrato
        </button>
      </div>
      <div className="d-flex">
        <input
          type="checkbox"
          name="confirm_payment"
          id="confirm_payment"
          checked={contractAccepted}
          onChange={() => setContractAccepted(prev => !prev)}
        />
        <label
          className="ps-4 py-4"
          htmlFor="confirm_payment"
        >{`Eu, ${studentData.client.name} concordo com os termos do contrato.`}</label>
      </div>
      <div>
        <button
          type="submit"
          className="btn btn-primary end mb-4"
          onClick={() => confirmContract()}
          disabled={!contractAccepted}
        >
          Confirmar contratação
        </button>
      </div>
      {createdFinancialMoviments && createdFinancialMoviments.length > 0 && (
        <div>
          <h3 className="col-form-label fw-bold form-label fw-bold fs-5">
            Parcela do primeiro pagamento (Boleto)
          </h3>

          <div>
            <div className="table-responsive">
              <table
                id="kt_datatable"
                className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
              >
                <thead className="border-bottom border-end">
                  <tr>
                    <th>Cód</th>
                    <th>Data de vencimento</th>
                    <th>Valor</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {createdFinancialMoviments?.map(
                    (financial_moviment, index) => {
                      if (index !== 0) return <></>
                      return (
                        <MenuTableRow key={financial_moviment.id}>
                          <td>{financial_moviment.id}</td>
                          <td>{financial_moviment.due_date.slice(0, 10)}</td>
                          <td>{currencyMask(financial_moviment.value)}</td>
                          <td>
                            <LinkContainer className="gap-2">
                              <div>
                                {financial_moviment.financialMovimentsPayments && (
                                  <span
                                    className="fa fa-print"
                                    style={{
                                      border: 'none',
                                      color: '#009EF7',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() =>
                                      getPdfBoleto(
                                        financial_moviment
                                          .financialMovimentsPayments[0].id,
                                        financial_moviment
                                          .financialMovimentsPayments[0]
                                          .bar_code
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </LinkContainer>
                          </td>
                        </MenuTableRow>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
