import { IPropsHeader } from 'components/Header'
import React, { FC, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import avatar from '../../assets/media/avatars/300-1.jpg'

import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'

const HeaderUserMenu = ({ avatarURL }: IPropsHeader) => {
  const history = useHistory()
  const { signOut, user } = useAuth()
  const { addToast } = useToast()
  const splitedName = user?.name?.split(' ')
  const firstName = splitedName?.[0]
  const lastName = splitedName?.[splitedName?.length - 1]

  const handleLogout = useCallback(() => {
    signOut()
    history.push('/')
    addToast({
      type: 'info',
      title: 'Logout realizado com sucesso!'
    })
  }, [signOut, history, addToast])

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img
              alt="Logo"
              src={avatarURL || avatar}
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {firstName} {lastName}
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Admin
              </span> */}
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>
      {/* <div className="menu-item px-5">
        <a href={'/users/profile'} className="menu-link px-5">
          My Profile
        </a>
        <Link to={'/users/profile'} className="menu-link px-5">
          My Profile
        </Link>
      </div> */}
      <div className="menu-item px-5">
        <a onClick={handleLogout} className="menu-link px-5">
          Logout
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
