import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import CustomDataTable from 'components/CustomDataTable'
import { useAuth } from 'hooks/auth'
import { makeStringWithAnd } from 'utlis/makeAStringWithAnd'
import { sortWeek } from 'utlis/sortWeek'

type ListType = IContractPlanCreate & {
  student_name: string
}

const MenuFoodsDishesList = (): JSX.Element => {
  const [contracts, setContracts] = useState<ListType[]>([])
  const { user } = useAuth()

  const getContracts = useCallback(async () => {
    const contractsListResponse = await api.get(
      `commercial/contracts/byClient/${user.id}`
    )
    const contracts = contractsListResponse.data
    const contractPlans: ListType[] = []
    contracts
      .filter((a: any) =>
        ['Ativo', 'Aguardando pagamento'].includes(a.situation)
      )
      .forEach((contract: any) => {
        if (contract.contract_plans) {
          contract.contract_plans.forEach((contractPlan: any) => {
            let days
            if (contractPlan?.frequency_days_of_week) {
              days = makeStringWithAnd(
                sortWeek(
                  JSON.parse(contractPlan?.frequency_days_of_week)
                    ?.frequency_days_of_week
                )
              )
            }
            contractPlans.push({
              ...contractPlan,
              student_name: contract.student.name,
              days
            })
          })
        }
      })
    setContracts(contractPlans)
  }, [user])

  useEffect(() => {
    getContracts()
  }, [getContracts])

  return (
    <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={[]}>
      <div>
        <h3 className="h3 pt-6">Listagem</h3>
      </div>
      <CustomDataTable
        customItems={contracts}
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-calendar',
            linkTo: item => `/commercial/menuFoods/view/${item.id}`
          }
        ]}
      />
    </Container>
  )
}
export default MenuFoodsDishesList
