import ClientsUpdate from '../../pages/Commercial/Clients/Register/Update'
import ClientsView from '../../pages/Commercial/Clients/Register/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/profile" component={ClientsView} exact />
    <Route path="/profile/update" component={ClientsUpdate} exact />
  </CustomSwitch>
)
