/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'

import {
  apiCreate,
  apiListInstitutions,
  apiUpdate,
  apiListMenuFoodTypes
} from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import api from 'services/api'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'

import { HasCompositionTab } from '../HasComposition'
import { IconRemove } from '../HasComposition/Table/style'
import { ISelectFormInstitution, useMenuFoodContext } from '../../contexts'
import moment from 'moment'
import axios from 'axios'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IMenuFoodData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  isChild?: boolean
  original_menu_food_id?: number
  setMenuFood?: React.Dispatch<React.SetStateAction<IMenuFoodData>>
}

export const FormMenuFood = ({
  isOpenInModal,
  initialValues,
  typeForm,
  isChild,
  original_menu_food_id,
  setMenuFood
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<IMenuFoodData>(undefined)
  const [menuFoodTypes, setMenuFoodTypes] = useState<IMenuFoodType[]>([])
  const [allInstitutions, setAllInstitutions] = useState<IInstitutionData[]>([])
  const [hasItems, setHasItems] = useState<string>()
  const {
    addInstitutionForm,
    removeOneInstitution,
    selectFormsInstitutions,
    composition,
    checkActualErrors,
    updateSelectFormsInstitutions,
    clearInstitutionsAndItems
  } = useMenuFoodContext()

  // useEffect(() => {
  //   clearInstitutionsAndItems()
  // }, [])

  const [institutionsIndexDuplicatedId, setInstitutionsIndexDuplicatedId] =
    useState<ISelectFormInstitution[]>([])

  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | string>()
  const [endDate, setEndDate] = useState<Date | string>()
  useEffect(() => {
    api
      .get(apiListMenuFoodTypes())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IMenuFoodType, b: IMenuFoodType) => a.name.localeCompare(b.name)
        )
        setMenuFoodTypes(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os tipos de cardápio',
          description:
            'Houve um erro ao carregar os tipos de cardápio disponíveis no banco de dados.'
        })
      })

    api
      .get(apiListInstitutions())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_name.localeCompare(b.company_name)
        )
        setAllInstitutions(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 61)

  const last30Days = new Date()
  last30Days.setDate(last30Days.getDate() - 31)

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      if (
        initialValues?.start_validity &&
        typeof initialValues.start_validity === 'string'
      ) {
        const date = moment(
          initialValues?.start_validity,
          'DD/MM/YYYY'
        ).toDate()
        initialValues.start_validity = date
      }
      if (
        initialValues?.end_validity &&
        typeof initialValues.end_validity === 'string'
      ) {
        const date = moment(initialValues?.end_validity, 'DD/MM/YYYY').toDate()
        initialValues.end_validity = date
      }

      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  useEffect(() => {
    initialValues?.has_items ? setHasItems('true') : setHasItems('false')
  }, [initialValues?.has_items])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IMenuFoodDataCreate) => {
    const id = initialValues?.idUpdate
    const hasItemError = checkActualErrors()
    data.has_items = data.has_items === 'true'

    if (hasItemError && data.has_items) {
      return
    }

    data = deleteEmptyFields(data)
    data.dishes = composition.map(dish => {
      return {
        dish_id: dish.dish_id,
        dish_amount: dish.dish_amount,
        is_default_dish: dish.is_default_dish
      }
    })
    if (data.has_items === false) {
      data.dishes = []
    }
    data.menu_food_type_id = +data.menu_food_type_id
    const dataEntries = Object.entries(data)
    const institutionsIdAdd = dataEntries
      .filter(([key]) => key.includes('institution_id'))
      .map(([, value]) => +value)
    data.institutions_id = institutionsIdAdd
    console.log(data)
    const dataKeys = Object.keys(data)

    const institutionsAdd = dataKeys.filter(key =>
      key.includes('institution_id')
    )
    for (let i = 0; i < institutionsAdd.length; i += 1) {
      delete data[institutionsAdd[i] as keyof IMenuFoodDataCreate]
    }

    const duplicatedInstitutions: {
      institution_id: number
      indexSelects: number[]
    }[] = []

    institutionsIdAdd.forEach((institutionId, index) => {
      const isInstitutionDuplicated = institutionsIdAdd.some(
        (institution, i) => institutionId === institution && index !== i
      )
      const isInstitutionAlreadyAddToTheDuplicatedArray =
        duplicatedInstitutions.some(
          ({ institution_id }) => institution_id === institutionId
        )

      if (
        isInstitutionDuplicated &&
        !isInstitutionAlreadyAddToTheDuplicatedArray
      ) {
        const institution_id = institutionsIdAdd.find(
          (institution, i) => institutionId === institution && index !== i
        )
        const indexInstitutions = institutionsIdAdd
          .map((institution, index) => {
            if (institution_id === institution) {
              return index
            }
            return undefined
          })
          .filter(institutionIndex => institutionIndex !== undefined)

        indexInstitutions.shift()
        const institutionDuplicated: {
          institution_id: number
          indexSelects: number[]
        } = {
          institution_id,
          indexSelects: indexInstitutions
        }

        duplicatedInstitutions.push(institutionDuplicated)
      }
    })

    const indexOfDuplicatedInstitution = duplicatedInstitutions
      .map(institutionDuplicated => institutionDuplicated.indexSelects)
      .flat()

    const idOfInstitutions = selectFormsInstitutions.filter(
      (institutionId, index) =>
        indexOfDuplicatedInstitution.some(
          institutionIndex => institutionIndex === index
        )
    )

    setInstitutionsIndexDuplicatedId(idOfInstitutions)

    if (idOfInstitutions.length > 0) {
      return
    }

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          let dataCreate
          const { handleOnClose } = isOpenInModal

          if (isChild) {
            dataCreate = {
              ...data,
              original_menu_food_id
            }
          } else {
            dataCreate = {
              ...data
            }
          }

          activeLoading()

          try {
            const createdMenuFood = await api.post<IMenuFoodData>(
              apiCreate(),
              dataCreate
            )
            updateDataTable()
            handleOnClose()
            disableLoading()
            if (isChild) {
              setMenuFood(old => {
                const newMenuFood = { ...old }
                const menuFoodRelatedWithDateThreated = {
                  ...createdMenuFood.data,
                  start_validity: moment(
                    createdMenuFood.data.start_validity,
                    'YYYY-MM-DD'
                  ).format('DD/MM/YYYY'),
                  end_validity: moment(
                    createdMenuFood.data.end_validity,
                    'YYYY-MM-DD'
                  ).format('DD/MM/YYYY')
                }
                newMenuFood.menu_foods_related = [
                  ...newMenuFood.menu_foods_related,
                  menuFoodRelatedWithDateThreated
                ]
                return newMenuFood
              })
            }
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            if (axios.isAxiosError(error)) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description: error.response.data.message
              })
            }
            updateDataTable()
            handleOnClose()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }

            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response.data, 'here')
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()

            const updatedMenuFood = await api.put<IMenuFoodData>(
              apiUpdate(String(id)),
              dataUpdate
            )

            if (isChild) {
              setMenuFood(old => {
                const newMenuFood = { ...old }
                console.log(updatedMenuFood.data.start_validity)
                const menuFoodRelatedWithDateThreated = {
                  ...updatedMenuFood.data,
                  start_validity: updatedMenuFood.data.start_validity,
                  end_validity: updatedMenuFood.data.end_validity
                }
                const indexMenuFoodRelated =
                  newMenuFood.menu_foods_related.findIndex(
                    element => element.id === menuFoodRelatedWithDateThreated.id
                  )

                newMenuFood.menu_foods_related[indexMenuFoodRelated] =
                  menuFoodRelatedWithDateThreated
                return newMenuFood
              })
            }

            updateDataTable()
            setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          console.log(dataUpdate, 'xxxxxxxx')
          try {
            activeLoading()
            const res = await api.put(apiUpdate(String(id)), dataUpdate)
            console.log(dataUpdate, res.data, 'aq')
            // setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            history.push(nameActions.read.to)
            console.log(err.response.data, 'here')

            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <div className="card mb-5 mb-xl-10">
        <FormContainer className="form-body">
          <div className="row">
            <Select
              className="col-md-3"
              name="menu_food_type_id"
              label="Tipo"
              options={
                typeForm === 'create'
                  ? menuFoodTypes
                      .filter(menuFoodType => menuFoodType.is_active)
                      .map(menuFoodType => ({
                        name: menuFoodType.name,
                        value: menuFoodType.id
                      }))
                  : menuFoodTypes.map(menuFoodType => ({
                      name: menuFoodType.name,
                      value: menuFoodType.id
                    }))
              }
              blank
              rules={{ required: true }}
            />
            <Input
              name="name"
              className="col-md-3"
              rules={{ required: true }}
              label="Nome"
            />
            <DatePicker
              label="Vigência inicial"
              className="col-md-3"
              name="start_validity"
              rules={{ required: true }}
              value={startDate as string}
              selected={
                (startDate as Date) ||
                (defaultValues?.start_validity as Date) ||
                undefined
              }
              onChange={date => setStartDate(date)}
              controlled
            />
            <DatePicker
              label="Vigência final"
              className="col-md-3"
              name="end_validity"
              rules={{ required: true }}
              value={endDate as string}
              selected={
                (endDate as Date) ||
                (defaultValues?.end_validity as Date) ||
                undefined
              }
              onChange={date => setEndDate(date)}
              minDate={
                (startDate as Date) ||
                (defaultValues?.start_validity as Date) ||
                undefined
              }
              controlled
            />
          </div>
          <div className="row">
            {typeForm === 'update' && (
              <Select
                className=" col-md-3"
                name="is_active"
                label="Ativo"
                options={[
                  {
                    name: 'Sim',
                    value: 'true'
                  },
                  {
                    name: 'Não',
                    value: 'false'
                  }
                ]}
                blank
                defaultValue={'true'}
                rules={{ required: true }}
              />
            )}
            <Select
              className=" col-md-2"
              name="has_items"
              label="Possui itens?"
              value={hasItems}
              onChange={event => setHasItems(event.target.value)}
              options={[
                {
                  name: 'Sim',
                  value: 'true'
                },
                {
                  name: 'Não',
                  value: 'false'
                }
              ]}
              blank
              // rules={{ required: true }}
            />
          </div>
          <div className="row">
            <Textarea
              name="description"
              className=" col-md-12"
              label="Descrição"
              style={{ minHeight: 120 }}
              rules={{ required: true, position: 'left' }}
            />
          </div>
          <div className="separator my-5" />
          <div>
            {hasItems === 'true' && (
              <>
                <div style={{ margin: '0 -1rem' }}>
                  <h3
                    className="col-form-label fw-bold fs-6 bg-secondary"
                    style={{ padding: '0.7rem 1rem' }}
                  >
                    Itens
                  </h3>
                </div>
                <HasCompositionTab typeForm={typeForm} />
                <div className="separator my-5" />
              </>
            )}
          </div>
          <div style={{ margin: '0 -1rem' }}>
            <h3
              className="col-form-label fw-bold fs-6 bg-secondary"
              style={{ padding: '0.7rem 1rem' }}
            >
              Instituições
            </h3>
          </div>
          {selectFormsInstitutions.map(({ id, institution_id }) => {
            return (
              <div className="d-flex align-items-center gap-5" key={id}>
                <Select
                  className=" col-md-3"
                  name={`institution_id_${id}`}
                  label="Instituição"
                  options={
                    typeForm === 'create'
                      ? allInstitutions
                          .filter(institution => institution.is_active)
                          .map(institution => ({
                            name: institution.company_name,
                            value: institution.id
                          }))
                      : allInstitutions.map(institution => ({
                          name: institution.company_name,
                          value: institution.id
                        }))
                  }
                  defaultValue={''}
                  blank
                  value={institution_id}
                  onChange={event => {
                    updateSelectFormsInstitutions(id, event.target.value)
                    setInstitutionsIndexDuplicatedId(old => {
                      return old.filter(element => element.id !== id)
                    })
                  }}
                  customizedError={
                    institutionsIndexDuplicatedId?.some(
                      duplicatedId => duplicatedId.id === id
                    )
                      ? 'Essa instituição já foi selecionada!'
                      : ''
                  }
                  controlled
                />

                {selectFormsInstitutions.length > 1 && (
                  <div className="pt-6">
                    <IconRemove onClick={() => removeOneInstitution(id)} />
                  </div>
                )}
              </div>
            )
          })}
          <div className="separator my-5" />
          <button
            type="button"
            className="btn btn-dark btn-sm mb-5"
            onClick={() => addInstitutionForm()}
          >
            + Instituição
          </button>
        </FormContainer>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => checkActualErrors()}
          >
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
