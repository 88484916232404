import React from 'react'
import { RegisterOptions, UseFormRegister } from 'react-hook-form'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import { DateContainer, DatePickerContainer, Error } from './styles'
import { FiAlertCircle } from 'react-icons/fi'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
registerLocale('ptBR', ptBR)

type DateProps = ReactDatePickerProps & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  validateOfAge?: boolean
  refuseDateAfterToday?: boolean
  acceptedDays?: number[]
}

export function DateInput({
  register,
  name,
  label,
  rules,
  errors,
  hasError,
  className,
  validateOfAge,
  refuseDateAfterToday,
  acceptedDays,
  ...rest
}: DateProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  const inputMaskFormat = 'DD/MM/YYYY'

  return (
    <DatePickerContainer
      className={className}
      error={error?.type === 'required'}
    >
      {label && (
        <label
          htmlFor={name}
          className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
        >
          {label}
        </label>
      )}
      <DateContainer error={error?.type === 'required'}>
        <DatePicker
          dateFormat="P"
          {...(register && register(name, rules))}
          locale="ptBR"
          className="form-control form-control-solid"
          customInput={
            <IMaskInput
              mask={Date}
              pattern={'d/m/Y'}
              format={date => {
                return moment(date).format(inputMaskFormat)
              }}
              parse={value => {
                return moment(value, inputMaskFormat).toDate()
              }}
              validate={(date: string) => {
                if (date.length === 10) {
                  if (refuseDateAfterToday) {
                    if (moment(date, inputMaskFormat).isAfter(moment())) {
                      return false
                    }
                    if (validateOfAge) {
                      const age = moment().diff(
                        moment(date, inputMaskFormat),
                        'year'
                      )
                      if (age < 18) {
                        return false
                      }
                    }
                  }
                  if (acceptedDays) {
                    const selectDay = moment(date, inputMaskFormat).date()
                    const isDayAllowed = acceptedDays?.some(
                      day => day === selectDay
                    )
                    console.log(isDayAllowed)
                    if (isDayAllowed) {
                      return true
                    }
                    return false
                  }
                }
                return true
              }}
            />
          }
          {...rest}
        />
        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </DateContainer>
    </DatePickerContainer>
  )
}
