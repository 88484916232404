import Form, { Input, Select as SelectStandard } from 'components/Form'
import { useParams } from 'react-router-dom'
import TableWithItems from '../TableWithItens'
import { BsCircle, BsCheckCircle } from 'react-icons/bs'
import { Stepper } from '../Stepper'
import { Card, ButtonCircle, CardText, CardSubText } from './styles'
import { useEffect, useState, useRef } from 'react'
import api from 'services/api'
import {
  apiCreate,
  apiCreateFinancialMoviment,
  apiGetPdfContract,
  apiGetPdfFinancialMoviment,
  apiGetPdfPreviewContract,
  apiGetStudent,
  apiListMenuFoodDishes,
  apiListMenuFoodTypes,
  apiListPlans
} from '../../domain/api'
import axios, { AxiosResponse } from 'axios'
import {
  genericMaskPercentage,
  currencyMask,
  convertValueMaskInNumberWithTwoZero,
  convertValueMaskInNumberWithTwoZeroDigits,
  percentualMask
} from 'utlis/mask'

import ToolTipError from 'components/ToolTipError'

import { useLoading } from 'hooks/loading'
import Modal from 'components/Modal'
import { useToast } from 'hooks/toast'
import { Alert } from 'components/Alert'
import {
  calculateDiscountToBRL,
  calculateValueWithDiscountToBRL,
  calculateValueWithDiscountToNumber
} from 'utlis/calculateDiscount'
import Tooltip from 'components/Tooltip'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import Summary from '../Summary'
import { InfoContract } from '../InfoContract'
import ChooseItemsForm from '../ChooseItemsForm'
import ChooseDaysFrequencyForm from '../ChooseDaysFrequencyForm'
import moment from 'moment'
import {
  calculateAutomaticDiscount,
  IAutomaticDiscount,
  messagesPlanDiscount
} from '../../utils/calculateAutomaticDiscount'

const FormRegister = () => {
  const { id } = useParams<{ id: string }>()
  // INITIAL DATA
  const [studentData, setStudentData] = useState<IStudent>()
  const [defaultValues, setDefaultValues] = useState<any>()
  const [menuFoodTypeData, setMenuFoodTypeData] = useState<IMenuFoodType[]>()
  const [optionsMenuFoodType, setOptionsMenuFoodTypeData] = useState<
    { value: string; name: string }[]
  >([])
  const [allPlans, setAllPlans] = useState<IPlan[]>(undefined)
  const [menuDishes, setMenuDishes] = useState<IMenuDish[]>(undefined)
  // SELECTED FORMS
  const [selectedMenuFoodTypeId, setSelectedMenuFoodTypeId] = useState<string>()
  const [selectedMenuFoodId, setSelectedMenuFoodId] =
    useState<number>(undefined)
  const [selectedPlanId, setSelectedPlanId] = useState<number>(undefined)
  const [selectedPlanName, setSelectedPlanName] = useState<string>(undefined)
  const [selectedPlanFrequency, setSelectedPlanFrequency] =
    useState<ISelectedPlanFrequency>(undefined)
  const [discount, setDiscount] = useState<string>(undefined)
  const [discountTotal, setDiscountTotal] = useState<string>(undefined)
  const [selectedDishes, setSelectedDishes] =
    useState<ISelectedDish[]>(undefined)
  const [frequencyDaysOfWeek, setFrequencyDaysOfWeek] = useState<string[]>([])

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenModalFrequency, setIsOpenModalFrequency] =
    useState<boolean>(false)
  const [isOpenModalDetails, setIsOpenModalDetails] = useState<boolean>(false)
  const [isOpenModalTableWithItems, setIsOpenModalTableWithItems] =
    useState<boolean>(false)

  const [menuName, setMenuName] = useState<string>(undefined)
  const [actualStep, setActualStep] = useState<TActualStep>('Select')
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    isMenuFoodSelected: true,
    isPlanFrequencySelected: true,
    isPlanSelected: true,
    isAllowedToChangeAllSteps: false,
    isAllowedToChangeStepPayament: false
  })
  const [alert, setAlert] = useState<IContractAlert>({
    message: '',
    isActive: false
  })
  const [contractPlans, setContractPlans] = useState<IContractPlanDisplay[]>([])
  const [menuFood, setMenuFood] = useState<IMenuFood>()
  const [dueDate, setDueDate] = useState<string | Date>()
  const [selectedPayamentForm, setSelectedPayamentForm] =
    useState<TFormPayament>(undefined)
  const [createdFinancialMoviments, setCreatedFinancialMoviments] =
    useState<IFinancialMoviment[]>()
  const [createdContract, setCreatedContract] = useState<IContract>()
  const [recurrenceNumber, setRecurrenceNumber] = useState<number>()
  const [actualSelectedDishes, setActualSelectedDishes] =
    useState<ISelectedDish[]>()

  const [automaticDiscount, setAutomaticDiscount] =
    useState<IAutomaticDiscount>()
  const planValuePayament = calculateValueWithDiscountToBRL(
    discount,
    +selectedPlanFrequency?.value
  )

  const totalPlanFrequency = contractPlans.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.value_with_discount,
    0
  )
  const valuePayament = calculateValueWithDiscountToBRL(
    discountTotal,
    totalPlanFrequency
  )

  const handlerClickButtonConfirmAlert = () => {
    setAlert({
      message: '',
      isActive: false
    })
  }

  const refModal = useRef(null)
  const refModalTable = useRef(null)

  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  const getPdfBoleto = (
    financialMovimentPaymentId: number,
    barCode: string
  ) => {
    activeLoading()
    api
      .get(apiGetPdfFinancialMoviment(financialMovimentPaymentId.toString()))
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.data.pdf}`

        const downloadLink = document.createElement('a')
        const fileName = `Cliente - ${studentData.client_id} Numero -${barCode}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        // window.open(linkSource)
        downloadLink.click()

        disableLoading()
      })
  }

  const getPdfContract = (id: number) => {
    activeLoading()

    api
      .get(apiGetPdfContract(id.toString()), { responseType: 'blob' })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
  }

  const getItemsMenuFood = (id: string) => {
    api
      .get(apiListMenuFoodDishes(id))
      .then((res: AxiosResponse<IMenuFoodData>) => {
        const { data } = res
        setMenuDishes(data.menu_dishes)
        disableLoading()
        setIsOpenModal(true)
      })
  }

  const handleClickOnOpenModal = (id: string, name: string) => {
    activeLoading()
    setMenuName(name)
    getItemsMenuFood(id)
  }

  const handleClickOnOpenModalDescription = (menuFood: IMenuFood) => {
    setMenuName(menuFood.name)
    setMenuFood(menuFood)
    setIsOpenModalDetails(true)
  }
  const handleClickOnCloseModalDescription = () => {
    setIsOpenModalDetails(false)
  }

  const handleClickOnOpenModalTableWithItems = (
    actualDishes: ISelectedDish[]
  ) => {
    setIsOpenModalTableWithItems(true)
    setActualSelectedDishes(actualDishes)
  }

  const handleClickOnCloseModalTableWithItems = () => {
    setIsOpenModalTableWithItems(false)
  }

  const handleClickOnClose = () => {
    setIsOpenModal(false)
    setMenuDishes(undefined)
    setMenuName(undefined)
  }

  const handleClickOnCloseModalFrequency = () => {
    setIsOpenModalFrequency(false)
  }

  const createFinancialMoviment = (contractData: IContract) => {
    const value = convertValueMaskInNumberWithTwoZero(valuePayament)
    let financialData: ICreateFinancialMoviment = {
      client_id: studentData.client_id,
      contract_id: contractData.id,
      description: 'Contratação de um plano',
      value,
      nf_status: 'Wainting',
      operation_type: 'D',
      sub_category_id: 1,
      category_id: 1,
      payment_gateway_id: 1, // alterar
      due_date: moment(dueDate, 'DD/MM/YYYY').toDate()
    }
    if (recurrenceNumber > 1) {
      financialData = {
        ...financialData,
        number_recurrence: recurrenceNumber,
        recurrence: true,
        frequency: 'm',
        value: value / recurrenceNumber
      }
    }
    console.log(financialData)

    api
      .post(apiCreateFinancialMoviment(), financialData)
      .then(res => {
        setCreatedFinancialMoviments(res.data)
        setActualStep('Confirm')
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Não foi possível fazer a criação do contrato.',
          description: 'Por favor, complete todos os dados corretamente.'
        })
        if (axios.isAxiosError(err)) {
          console.log(err.response.data)
        }
      })
  }

  const activeMenuFoodsByMenuType = menuFoodTypeData
    ?.find(({ id }) => id === +selectedMenuFoodTypeId)
    ?.menu_foods.filter(({ is_active }) => is_active)

  const activePlansByMenuFood = allPlans?.filter(
    ({ menu_food_id, is_active }) =>
      menu_food_id === selectedMenuFoodId && is_active
  )

  const activePlansFrequencyByPlan = allPlans
    ?.find(({ id }) => selectedPlanId === id)
    ?.plans_frequency.filter(({ is_active }) => is_active)

  useEffect(() => {
    api.get(apiGetStudent(id)).then((res: AxiosResponse<IStudent>) => {
      const { data } = res
      setStudentData(data)
      const studentDataToForm = {
        client_name: data.client.name,
        student_name: data.name,
        institution_social_name: data.institution.company_social_name
      }
      setDefaultValues(studentDataToForm)
    })
  }, [id])

  useEffect(() => {
    if (contractPlans.length > 0 && studentData.client) {
      console.log(studentData.client)
      const calculatedAutomaticDiscount = calculateAutomaticDiscount(
        contractPlans,
        studentData.client,
        studentData
      )
      setAutomaticDiscount(calculatedAutomaticDiscount)
      console.log(calculatedAutomaticDiscount.automaticDiscountAdded)
      setDiscountTotal(
        genericMaskPercentage(
          calculatedAutomaticDiscount.automaticDiscountAdded.toString()
        )
      )
    }
  }, [contractPlans, studentData])

  useEffect(() => {
    if (studentData) {
      api
        .get(apiListMenuFoodTypes())
        .then((res: AxiosResponse<IMenuFoodType[]>) => {
          const { data } = res
          setMenuFoodTypeData(data)
          const options = data
            .filter(({ is_active }) => is_active)
            .map(({ id, name }) => {
              return {
                value: id.toString(),
                name: name
              }
            })
            .sort((a, b) => {
              return a.name.localeCompare(b.name)
            })
          setOptionsMenuFoodTypeData(options)
        })
    }
  }, [id, studentData])

  useEffect(() => {
    api.get(apiListPlans()).then((res: AxiosResponse<IPlan[]>) => {
      const { data } = res
      setAllPlans(data)
    })
  }, [])

  const alertNotActiveMenuFoodsInMenuFoodType = (selectedId: string) => {
    const isSomeMenuFoodActive = menuFoodTypeData
      ?.find(({ id }) => id === +selectedId)
      ?.menu_foods.some(({ is_active }) => is_active)
    if (!isSomeMenuFoodActive) {
      setAlert({
        isActive: true,
        message:
          'Não há cardápios registrados no tipo selecionado. Por favor, selecione outro tipo de cardápio.'
      })
    }
  }

  const alertNotActivePlansInMenuFood = (id: number) => {
    const isSomePlanActive = allPlans?.some(
      ({ menu_food_id, is_active }) => menu_food_id === id && is_active
    )
    if (!isSomePlanActive) {
      setAlert({
        isActive: true,
        message:
          'Não há planos registrados no cardápio selecionado. Por favor, selecione outro cardápio.'
      })
    }
  }
  const alertNotActivePlansFrequencyInPlan = (selectedId: number) => {
    const isSomePlanFrequencyActive = allPlans
      ?.find(({ id }) => selectedId === id)
      ?.plans_frequency.some(({ is_active }) => is_active)

    if (!isSomePlanFrequencyActive) {
      setAlert({
        isActive: true,
        message:
          'Não há frequências registradas no plano selecionado. Por favor, selecione outro plano.'
      })
    }
  }

  // history.push(`/${baseUrl}/${path}`)

  const addPlanToContractData = () => {
    if (!selectedPlanId || !selectedPlanFrequency || !selectedMenuFoodId) {
      addToast({
        type: 'error',
        title: 'Não foi possível adicionar o plano.',
        description: 'Por favor, complete a seleção de um plano frequência.'
      })
      setFormErrors({
        isMenuFoodSelected: !!selectedMenuFoodId,
        isPlanFrequencySelected: !!selectedPlanFrequency?.frequency,
        isPlanSelected: !!selectedPlanId,
        isAllowedToChangeStepPayament: false,
        isAllowedToChangeAllSteps: false
      })
      return
    }
    const {
      id,
      periodicity: periodicity_payament,
      value: plan_value
    } = selectedPlanFrequency
    console.log(frequencyDaysOfWeek)
    const planContract: IContractPlanDisplay = {
      plan_id: selectedPlanId,
      plan_frequency_id: id,
      discount: convertValueMaskInNumberWithTwoZeroDigits(discount),
      plan_value: +plan_value,
      periodicity_payament,
      plan_name: selectedPlanName,
      value_with_discount: calculateValueWithDiscountToNumber(
        discount,
        +plan_value
      ),
      items_selected: JSON.stringify(selectedDishes),
      frequency_days_of_week: JSON.stringify({
        frequency_days_of_week: frequencyDaysOfWeek
      }),
      menu_food: menuFood
    }

    setContractPlans(old => [...old, planContract])
    addToast({ title: 'Plano adicionado com sucesso!' })
    setSelectedMenuFoodTypeId('')
    setSelectedMenuFoodId(undefined)
    setMenuFood(undefined)
    setSelectedPlanId(undefined)
    setSelectedPlanName(undefined)
    setSelectedPlanFrequency(undefined)
    setSelectedDishes(undefined)
    setDiscount('')
  }

  const onSubmitForm = async (data: IContractForm) => {
    if (contractPlans.length === 0) {
      if (!selectedPlanId || !selectedPlanFrequency || !selectedMenuFoodId) {
        addToast({
          type: 'error',
          title: 'Não foi possível realizar o cadastro.',
          description: 'Por favor, preencha todos os campos.'
        })
        setFormErrors({
          isMenuFoodSelected: !!selectedMenuFoodId,
          isPlanFrequencySelected: !!selectedPlanFrequency?.frequency,
          isPlanSelected: !!selectedPlanId,
          isAllowedToChangeStepPayament: false,
          isAllowedToChangeAllSteps: false
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Não foi possível dar sequência no cadastro.',
        description: 'Por favor, clique em "Adicionar plano".'
      })
      return
    }

    if (actualStep === 'Select') {
      setActualStep('Payament')
      setFormErrors(old => ({ ...old, isAllowedToChangeStepPayament: true }))
      return
    }
    // 2. If all is checked, pass to phase 2
    if (actualStep === 'Payament') {
      // selectedDishes
      console.log(data)
      if (!dueDate || !recurrenceNumber) {
        addToast({
          type: 'error',
          title: 'Não é possível passar para próxima etapa.',
          description: 'Por favor, preencha todos os campos.'
        })
        return
      }
      setFormErrors(old => ({ ...old, isAllowedToChangeAllSteps: true }))
      setActualStep('Confirm')
      // Api call <- Here ->
    }
    // 3. If all in phase 3 is completed, pass to phase 3
  }

  const createContractData = async (): Promise<IContract> => {
    const { client_id, id: student_id, institution_id } = studentData
    const plans_contract: IContractPlanCreate[] = contractPlans.map(
      ({
        periodicity_payament,
        plan_frequency_id,
        plan_id,
        plan_value,
        value_with_discount,
        discount,
        items_selected,
        frequency_days_of_week
      }) => {
        return {
          plan_id,
          plan_frequency_id,
          discount,
          plan_value,
          periodicity_payament,
          value_with_discount,
          items_selected,
          frequency_days_of_week
        }
      }
    )
    // automaticDiscount
    const contractData: IContract = {
      client_id,
      student_id,
      institution_id,
      value_without_discount: totalPlanFrequency,
      discount: convertValueMaskInNumberWithTwoZeroDigits(discountTotal),
      value_payament: convertValueMaskInNumberWithTwoZero(valuePayament),
      periodicity_payament: 'Anual',
      plans_contract,
      form_payament: selectedPayamentForm,
      discounts_add: JSON.stringify(automaticDiscount.discounts_add)
    }
    return contractData
  }

  const previewContract = async () => {
    activeLoading()
    const dataToGeneratePdfPreview = {
      client: studentData.client,
      student: studentData,
      institution: studentData.institution,
      form_payament: selectedPayamentForm,
      value_payament: convertValueMaskInNumberWithTwoZero(valuePayament),
      due_date: moment(dueDate, 'DD/MM/YYYY').toDate(),
      discount: discountTotal,
      created_at: new Date(),
      plans_contract: contractPlans
    }
    await api
      .post(apiGetPdfPreviewContract(), dataToGeneratePdfPreview, {
        responseType: 'blob'
      })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
      .catch(err => console.log(err.response.data))
    disableLoading()
  }
  const confirmContract = async () => {
    const contractData = await createContractData()
    activeLoading()
    await api
      .post(apiCreate(), contractData)
      .then(res => {
        addToast({
          type: 'success',
          title: 'Contrato registrado com sucesso.'
        })
        setCreatedContract(res.data)
        if (selectedPayamentForm === 'Boleto') {
          createFinancialMoviment(res.data)
        }
        // const [, baseUrl] = location.pathname.split('/')
        // history.push(`/${baseUrl}/clients/view/${studentData.client_id}`)
      })
      .catch(err => console.log(err.response.data))
    disableLoading()
  }

  const changeStep = (step: TActualStep) => {
    if (step === 'Payament') {
      return
    }
    if (step !== 'Confirm' && formErrors.isAllowedToChangeStepPayament) {
      setActualStep(step)
    }
    if (formErrors.isAllowedToChangeAllSteps) {
      setActualStep(step)
    }
  }

  return (
    <div>
      <Stepper
        steps={[
          {
            status:
              actualStep === 'Select'
                ? 'current'
                : actualStep === 'Confirm' || actualStep === 'Payament'
                ? 'completed'
                : '',
            step: '1',
            title: 'Seleção do cardápio',
            step_name: 'Select'
            // changeStep: setActualStep('Select')
          },
          {
            status:
              actualStep === 'Payament'
                ? 'current'
                : actualStep === 'Confirm'
                ? 'completed'
                : '',
            step: '2',
            title: 'Gerar o pagamento',
            step_name: 'Payament'
          },
          {
            status: actualStep === 'Confirm' ? 'current' : '',
            step: '3',
            title: 'Confirmação e emissão do contrato',
            step_name: 'Confirm'
          }
        ]}
        changeStep={changeStep}
      />
      <Form
        onSubmit={onSubmitForm}
        setReset
        defaultValues={{
          ...defaultValues
        }}
      >
        <div className="card mb-5 mb-xl-10">
          {actualStep === 'Select' && (
            <div id="select__form__menu-food">
              <div className="row">
                <Input
                  name="client_name"
                  className={'col-md-4'}
                  disabled
                  label="Cliente"
                />
                <Input
                  name="student_name"
                  className={'col-md-4'}
                  label="Estudante"
                  disabled
                />
                <Input
                  name="institution_social_name"
                  className={'col-md-4'}
                  disabled
                  label="Instituição"
                />
              </div>
              <div className="separator my-5" />
              <div className="row">
                <div>
                  <div>
                    <h3 className="col-form-label fw-bold fs-6 ">
                      Tipo de cardápio
                    </h3>
                  </div>
                </div>
                <SelectStandard
                  options={optionsMenuFoodType}
                  name="menu_food_type_id"
                  value={selectedMenuFoodTypeId}
                  onChange={event => {
                    setSelectedMenuFoodTypeId(event.target.value)
                    setSelectedMenuFoodId(undefined)
                    setMenuFood(undefined)
                    setSelectedPlanId(undefined)
                    setSelectedPlanFrequency(undefined)
                    alertNotActiveMenuFoodsInMenuFoodType(event.target.value)
                  }}
                  // controlled
                  rules={{
                    required: contractPlans?.length === 0
                  }}
                  className={'col-md-4'}
                  blank
                />
              </div>
              {selectedMenuFoodTypeId && (
                <>
                  <div className="separator my-5" />
                  <div className="row mb-5">
                    <div>
                      <div>
                        <h3
                          className={`col-form-label fw-bold fs-6
                       ${!formErrors.isMenuFoodSelected && 'text-danger'}`}
                        >
                          Cardápio{' '}
                          <span style={{ position: 'relative' }}>
                            {!formErrors.isMenuFoodSelected && (
                              <ToolTipError
                                title={'A seleção de um cardápio é obrigatória'}
                                position="right"
                              />
                            )}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      {activeMenuFoodsByMenuType &&
                        activeMenuFoodsByMenuType.length === 0 && (
                          <div>
                            Não há cardápios registrados no tipo selecionado.
                          </div>
                        )}
                      {selectedMenuFoodTypeId ? (
                        activeMenuFoodsByMenuType.map(actual_menu_food => {
                          const isUsedInInstitution =
                            studentData.institution.menu_institution.some(
                              ({ menu_food_id }) =>
                                actual_menu_food.id === menu_food_id
                            )

                          return (
                            <div className="col-xl-3 col-sm-4 mb-5" key={id}>
                              <Card
                                isSelected={
                                  actual_menu_food.id === selectedMenuFoodId
                                }
                                className="d-flex align-items-center flex-column gap-4"
                                height={300}
                                height_big_screen={270}
                              >
                                <ButtonCircle
                                  type="button"
                                  onClick={() => {
                                    if (
                                      actual_menu_food.id !== selectedMenuFoodId
                                    ) {
                                      if (actual_menu_food.has_items) {
                                        handleClickOnOpenModal(
                                          actual_menu_food.id.toString(),
                                          actual_menu_food.name
                                        )
                                      }
                                      setSelectedMenuFoodId(actual_menu_food.id)
                                      setMenuFood(actual_menu_food)
                                      setSelectedPlanId(undefined)
                                      setSelectedPlanFrequency(undefined)
                                      setFormErrors(old => ({
                                        ...old,
                                        isMenuFoodSelected: true
                                      }))
                                      alertNotActivePlansInMenuFood(
                                        actual_menu_food.id
                                      )
                                    }
                                  }}
                                  style={{ width: '22px', height: '22px' }}
                                >
                                  {actual_menu_food.id ===
                                  selectedMenuFoodId ? (
                                    <BsCheckCircle
                                      fontSize={22}
                                      color="#009EF7"
                                    />
                                  ) : (
                                    <BsCircle fontSize={20} color="#A1A5B7" />
                                  )}
                                </ButtonCircle>
                                <h3 style={{ color: '#3F4254' }}>
                                  {actual_menu_food.name}
                                </h3>
                                <CardText>
                                  {actual_menu_food?.description}
                                </CardText>

                                {actual_menu_food.has_items &&
                                  actual_menu_food.id ===
                                    selectedMenuFoodId && (
                                    <div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <button
                                          className="card__see-items text-primary fs-6"
                                          onClick={() =>
                                            handleClickOnOpenModal(
                                              actual_menu_food.id.toString(),
                                              actual_menu_food.name
                                            )
                                          }
                                          type="button"
                                        >
                                          Editar itens selecionados
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {isUsedInInstitution && (
                                  <CardSubText className="justify-align-self-end self-end ">
                                    Cardápio utilizado na instituição{' '}
                                    {
                                      studentData.institution
                                        .company_social_name
                                    }
                                  </CardSubText>
                                )}
                              </Card>
                            </div>
                          )
                        })
                      ) : (
                        <div>Selecione um tipo de cardápio</div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {selectedMenuFoodId && (
                <>
                  <div className="separator my-5" />
                  <div className="row mb-5">
                    <div>
                      <div>
                        <h3
                          className={`col-form-label fw-bold fs-6 
                      ${!formErrors.isPlanSelected && 'text-danger'}`}
                          style={{ color: '#3F4254' }}
                        >
                          Plano
                          <span style={{ position: 'relative' }}>
                            {!formErrors.isPlanSelected && (
                              <ToolTipError
                                title={'A seleção de um plano é obrigatória'}
                                position="right"
                              />
                            )}
                          </span>
                        </h3>
                      </div>
                      <div className="row mb-5">
                        {selectedMenuFoodId &&
                          activePlansByMenuFood?.length === 0 && (
                            <div>
                              Não há planos registrados no cardápio selecionado.
                            </div>
                          )}
                        {selectedMenuFoodId ? (
                          activePlansByMenuFood.map(
                            ({ description, name, id }) => {
                              return (
                                <div
                                  className="col-xl-3 col-sm-4 mb-5"
                                  key={id}
                                >
                                  <Card
                                    isSelected={id === selectedPlanId}
                                    className="d-flex align-items-center flex-column gap-4"
                                  >
                                    <h3>{name}</h3>
                                    <ButtonCircle
                                      type="button"
                                      onClick={() => {
                                        if (id !== selectedPlanId) {
                                          setSelectedPlanId(id)
                                          setSelectedPlanName(name)
                                          setSelectedPlanFrequency(undefined)
                                          setFrequencyDaysOfWeek([])

                                          setFormErrors(old => ({
                                            ...old,
                                            isPlanSelected: true
                                          }))
                                          alertNotActivePlansFrequencyInPlan(id)
                                        }
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                    >
                                      {id === selectedPlanId ? (
                                        <BsCheckCircle
                                          fontSize={22}
                                          color="#009EF7"
                                        />
                                      ) : (
                                        <BsCircle
                                          fontSize={20}
                                          color="#A1A5B7"
                                        />
                                      )}
                                    </ButtonCircle>
                                    <p
                                      className="mt-2 fw-bold text-center"
                                      style={{
                                        color: '#7e8299',
                                        fontSize: '13px'
                                      }}
                                    >
                                      {description}
                                    </p>
                                  </Card>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div>Selecione um cardápio</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedPlanId && (
                <>
                  <div className="separator my-5" />
                  <div className="row mb-5">
                    <div>
                      <div>
                        <h3
                          className={`col-form-label fw-bold fs-6
                      ${!formErrors.isPlanFrequencySelected && 'text-danger'}`}
                        >
                          Frequência e valores
                          <span style={{ position: 'relative' }}>
                            {!formErrors.isPlanFrequencySelected && (
                              <ToolTipError
                                title={
                                  'A seleção de uma frequência é obrigatória'
                                }
                                position="right"
                              />
                            )}
                          </span>
                        </h3>
                      </div>

                      <div className="row">
                        {selectedPlanId &&
                          activePlansFrequencyByPlan?.length === 0 && (
                            <div>
                              Não há planos frequência registrados no tipo
                              selecionado.
                            </div>
                          )}
                        {selectedPlanId ? (
                          activePlansFrequencyByPlan?.map(
                            ({ id, frequency, payament_form }) => {
                              const { periodicity, value } = JSON.parse(
                                payament_form
                              ) as { periodicity: TPeriodicity; value: string }
                              if (contractPlans.length > 0) {
                                if (
                                  contractPlans[0].periodicity_payament !==
                                  periodicity
                                ) {
                                  return <></>
                                }
                              }
                              return (
                                <div
                                  className="col-xl-3 col-sm-4 mb-5"
                                  key={'plan_frequency' + id}
                                >
                                  <Card
                                    isSelected={
                                      id === selectedPlanFrequency?.id
                                    }
                                    className="d-flex align-items-center flex-column gap-4"
                                  >
                                    <ButtonCircle
                                      type="button"
                                      onClick={() => {
                                        if (id !== selectedPlanFrequency?.id) {
                                          setSelectedPlanFrequency({
                                            id,
                                            frequency,
                                            value,
                                            periodicity
                                          })
                                          setFrequencyDaysOfWeek([])

                                          setFormErrors(old => ({
                                            ...old,
                                            isPlanFrequencySelected: true
                                          }))
                                          setIsOpenModalFrequency(true)
                                        }
                                      }}
                                      style={{ width: '22px', height: '22px' }}
                                    >
                                      {id === selectedPlanFrequency?.id ? (
                                        <BsCheckCircle
                                          fontSize={22}
                                          color="#009EF7"
                                        />
                                      ) : (
                                        <BsCircle
                                          fontSize={20}
                                          color="#A1A5B7"
                                        />
                                      )}
                                    </ButtonCircle>
                                    <p className="fs-5 fw-bold text-center">
                                      {frequency}
                                    </p>
                                    <p className="fs-5 fw-bold text-center">
                                      {periodicity}
                                    </p>
                                    <p className="fs-5 fw-bold text-primary">
                                      {currencyMask(value)}
                                    </p>
                                  </Card>
                                </div>
                              )
                            }
                          )
                        ) : (
                          <div>Selecione um plano</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {selectedPlanFrequency && (
                <div className="row">
                  <Input
                    name="plan_value"
                    className="col-md-3 col-xl-2"
                    label="Valor do plano"
                    value={currencyMask(selectedPlanFrequency?.value)}
                    controlled
                    disabled
                  />
                  <Input
                    name="plan_discount"
                    className="col-md-3 col-xl-2"
                    label="Percentual do desconto"
                    endMessage="%"
                    // rules={{ required: true }}
                    value={discount}
                    onChange={event =>
                      setDiscount(genericMaskPercentage(event.target.value))
                    }
                  />
                  <Input
                    name="plan_discount_value"
                    className="col-md-3 col-xl-2"
                    label="Valor do desconto"
                    value={calculateDiscountToBRL(
                      discount,
                      +selectedPlanFrequency.value
                    )}
                    // value={discountPlanValue()}
                    controlled
                    disabled
                  />
                  <Input
                    name="plan_value_payament"
                    className="col-md-3 col-xl-2"
                    label="Valor total"
                    value={planValuePayament}
                    controlled
                    disabled
                  />
                </div>
              )}

              {selectedPlanFrequency && (
                <div className="d-flex justify-content-end pe-8">
                  <button
                    className="btn btn-primary mb-4 end-0"
                    type="button"
                    onClick={() => addPlanToContractData()}
                  >
                    {contractPlans.length === 0
                      ? 'Adicionar plano'
                      : 'Adicionar novo plano'}
                  </button>
                </div>
              )}
              {contractPlans && contractPlans.length > 0 && (
                <div className="d-flex gap-5 align-items-center">
                  <h3
                    className={`col-form-label fw-bold fs-6
                    `}
                  >
                    Planos e frequências adicionados
                  </h3>
                  <div className="h-100 d-flex align-items-center w-20">
                    <Tooltip
                      title='Para adicionar um plano, deve ser selecionado primeiramente um tipo de cardápio, que habilitará os cardápios disponíveis para esse tipo. Na sequência, seleciona-se o cardápio, habilitando os planos disponíveis para o cardápio selecionado. Selecionado um plano, escolha a frequência do plano. E por fim, clique no botão "Adicionar novo plano"'
                      widthContainer="auto"
                      width={500}
                    >
                      <AiOutlineQuestionCircle
                        fontSize={18}
                        style={{ position: 'initial' }}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}

              <div className="row mb-5">
                {contractPlans?.map(
                  ({
                    periodicity_payament,
                    plan_value,
                    plan_name,
                    value_with_discount,
                    discount,
                    menu_food,
                    items_selected
                  }) => {
                    return (
                      <div className="col-xl-3 col-sm-4 mb-5" key={id}>
                        <Card
                          className="d-flex align-items-center flex-column gap-4 h-100 justify-content-between"
                          isSelected
                        >
                          <h3 style={{ color: '#3F4254' }}>
                            {plan_name}
                            <span
                              className="fs-7 ps-5 text-primary"
                              onClick={() =>
                                handleClickOnOpenModalDescription(menu_food)
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              Detalhes do cardápio
                            </span>
                          </h3>
                          {items_selected && (
                            <CardSubText
                              fontSize="1rem"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const selectedDishes =
                                  JSON.parse(items_selected)
                                handleClickOnOpenModalTableWithItems(
                                  selectedDishes
                                )
                              }}
                            >
                              Itens selecionados
                            </CardSubText>
                          )}

                          <CardSubText fontSize="1rem">
                            {periodicity_payament}
                          </CardSubText>
                          <CardSubText fontSize="1rem">
                            Valor do Plano {currencyMask(plan_value)}
                          </CardSubText>
                          <CardSubText fontSize="1rem">
                            {discount > 0 &&
                              `Desconto ${calculateDiscountToBRL(
                                discount.toString(),
                                plan_value
                              )} (${percentualMask(discount)})`}
                          </CardSubText>
                          <CardSubText
                            fontSize="1rem"
                            className="fw-bold text-primary"
                          >
                            Valor Final {currencyMask(value_with_discount)}
                          </CardSubText>
                        </Card>
                      </div>
                    )
                  }
                )}
              </div>
              {contractPlans.length > 0 && (
                <>
                  <div className="row">
                    <Input
                      name="value"
                      className="col-md-3 col-xl-3"
                      label="Valor da contratação"
                      // Calcular a soma total
                      value={currencyMask(totalPlanFrequency)}
                      controlled
                      disabled
                    />
                    <Input
                      name="discount"
                      className="col-md-3 col-xl-3"
                      label="Percentual do desconto"
                      endMessage="%"
                      // rules={{ required: true }}
                      value={discountTotal}
                      onChange={event =>
                        setDiscountTotal(
                          genericMaskPercentage(event.target.value)
                        )
                      }
                    />
                    <Input
                      name="discount_value"
                      className="col-md-3 col-xl-3"
                      label="Valor do desconto"
                      value={calculateDiscountToBRL(
                        discountTotal,
                        totalPlanFrequency
                      )}
                      controlled
                      disabled
                    />
                    <Input
                      name="value_payament"
                      className="col-md-3 col-xl-3"
                      label="Valor final da contratação"
                      value={valuePayament}
                      controlled
                      disabled
                    />
                  </div>
                  {
                    <div>
                      {automaticDiscount?.discounts_add?.map(discountType => {
                        return (
                          <p key={discountType}>
                            {messagesPlanDiscount[discountType]}
                          </p>
                        )
                      })}
                    </div>
                  }
                </>
              )}
            </div>
          )}
          {actualStep === 'Payament' && (
            <Summary
              client_name={studentData.client.name}
              institution_name={studentData.institution.company_social_name}
              student_name={studentData.name}
              contract_plans={contractPlans}
              total={valuePayament}
              discount={calculateDiscountToBRL(
                discountTotal,
                totalPlanFrequency
              )}
              subTotal={currencyMask(totalPlanFrequency)}
              dueDate={dueDate}
              setDueDate={setDueDate}
              selectedPayamentForm={selectedPayamentForm}
              setSelectedPayamentForm={setSelectedPayamentForm}
              setRecurrenceNumber={setRecurrenceNumber}
              recurrenceNumber={recurrenceNumber}
            />
          )}
          {actualStep === 'Confirm' && (
            <InfoContract
              studentData={studentData}
              contractPlans={contractPlans}
              createdFinancialMoviments={createdFinancialMoviments}
              subTotal={currencyMask(totalPlanFrequency)}
              discount={calculateDiscountToBRL(
                discountTotal,
                totalPlanFrequency
              )}
              total={valuePayament}
              getPdfBoleto={getPdfBoleto}
              previewContract={previewContract}
              confirmContract={confirmContract}
              handleClickOnOpenModalDescription={
                handleClickOnOpenModalDescription
              }
              handleClickOnOpenModalTableWithItems={
                handleClickOnOpenModalTableWithItems
              }
            />
          )}
          <div
            className={`card-footer d-flex py-6 px-9 ${
              actualStep !== 'Payament'
                ? 'justify-content-end'
                : 'justify-content-between'
            }`}
          >
            {actualStep === 'Payament' && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  changeStep('Select')
                }}
              >
                Voltar
              </button>
            )}

            {actualStep !== 'Confirm' ? (
              <button type="submit" className="btn btn-primary">
                Próximo
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </Form>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={isOpenModal}
        pageTitle={`Escolha os itens do ${menuName}`}
        modalWithClose={false}
        Children={
          <ChooseItemsForm
            menuDishes={menuDishes}
            setSelectedDishes={setSelectedDishes}
            selectedDishes={selectedDishes}
            handleClickOnClose={handleClickOnClose}
          />
        }
      />

      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnCloseModalFrequency}
        isOpenModal={isOpenModalFrequency}
        pageTitle={'Escolha os dias da semana:'}
        modalWithClose={false}
        Children={
          <ChooseDaysFrequencyForm
            frequencyDaysOfWeek={frequencyDaysOfWeek}
            setFrequencyDaysOfWeek={setFrequencyDaysOfWeek}
            handleClickOnCloseModalFrequency={handleClickOnCloseModalFrequency}
            selectedPlanFrequency={selectedPlanFrequency}
          />
        }
      />
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnCloseModalDescription}
        isOpenModal={isOpenModalDetails}
        pageTitle={`Detalhes do ${menuName}`}
        Children={
          <div>
            <p>{menuFood?.description}</p>
          </div>
        }
      />
      <Modal
        refModal={refModalTable}
        onClickButtonCancel={handleClickOnCloseModalTableWithItems}
        isOpenModal={isOpenModalTableWithItems}
        pageTitle={'Itens escolhidos'}
        Children={
          <TableWithItems actualSelectedDishes={actualSelectedDishes} />
        }
      />
      <Alert
        message={alert.message}
        onlyConfirm
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        onClickCancelButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        isActive={alert.isActive}
      />
    </div>
  )
}

export default FormRegister
