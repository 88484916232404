import MenuFoodsList from '../../pages/Commercial/MenuFoods/Register/List'
import MenuFoodsCreate from '../../pages/Commercial/MenuFoods/Register/Create'
import MenuFoodsView from '../../pages/Commercial/MenuFoods/Register/View'
import MenuFoodsUpdate from '../../pages/Commercial/MenuFoods/Register/Update'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'
import { MenuFoodContextProvider } from 'pages/Commercial/MenuFoods/Register/contexts'

export const MenuFoodsRouter = (): JSX.Element => (
  <MenuFoodContextProvider>
    <CustomSwitch>
      <Route path="/commercial/menuFoods" exact component={MenuFoodsList} />
      <Route
        path="/commercial/menuFoods/create"
        component={MenuFoodsCreate}
        exact
      />
      <Route
        path="/commercial/menuFoods/view/:id"
        component={MenuFoodsView}
        exact
      />
      <Route
        path="/commercial/menuFoods/update/:id"
        component={MenuFoodsUpdate}
        exact
      />
    </CustomSwitch>
  </MenuFoodContextProvider>
)
