import DishesTypesList from '../../pages/Commercial/MenuFoods/DishesTypes/List'
import DishesTypesCreate from '../../pages/Commercial/MenuFoods/DishesTypes/Create'
import DishesTypesView from '../../pages/Commercial/MenuFoods/DishesTypes/View'
import DishesTypesUpdate from '../../pages/Commercial/MenuFoods/DishesTypes/Update'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const DishesTypesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/dishesTypes" exact component={DishesTypesList} />
    <Route
      path="/commercial/dishesTypes/create"
      component={DishesTypesCreate}
      exact
    />
    <Route
      path="/commercial/dishesTypes/view/:id"
      component={DishesTypesView}
      exact
    />
    <Route
      path="/commercial/dishesTypes/update/:id"
      component={DishesTypesUpdate}
      exact
    />
  </CustomSwitch>
)
