import React, { useEffect, useState } from 'react'

import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import {
  apiCreate,
  apiUpdate,
  apiList,
  apiListEntityColumns
} from '../../domain/api'

import Form, { Input, Select } from '../../../../components/Form'
import { FormContent } from './styles'

type LabelData = {
  id?: number
  entity: string
  fields: any
}

export const FormLabel = (): JSX.Element => {
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState<LabelData[]>()
  const [entitiesColumns, setEntitiesColumns] = useState<LabelData[]>()
  const [actualEntityColumn, setActualEntityColumn] = useState<LabelData>()
  const [actualId, setActualId] = useState<number>()

  useEffect(() => {
    api.get(apiListEntityColumns()).then(res => setEntitiesColumns(res.data))
    api.get(apiList()).then(res => setDefaultValues(res.data))
  }, [])

  const DEFAULT_VALUES: any = {
    id: 'Cód',
    created_at: 'Cadastrado em',
    updated_at: 'Atualizado em',
    deleted_at: 'Deletado em'
  }

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = (data: LabelData) => {
    const idToUpdate = defaultValues
      .find(({ entity }) => entity === data.entity)
      ?.id.toString()
    const fields = { ...data }
    delete fields.entity
    const newData = {
      entity: data.entity,
      fields: fields
    }

    if (!data.entity) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o registro',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
    }

    if (idToUpdate) {
      api.put(apiUpdate(idToUpdate), newData).then(res => {
        setDefaultValues(old => {
          const newDefaultValues = [...old]
          const updatedIndex = newDefaultValues.findIndex(
            ({ id }) => id === +idToUpdate
          )
          newDefaultValues[updatedIndex] = res.data
          return newDefaultValues
        })
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro atualizado com sucesso'
        })
      })
    } else {
      api.post(apiCreate(), newData).then(res => {
        setDefaultValues(old => [...old, res.data])
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
      })
    }
  }

  let SQL_COMMAND = `
INSERT INTO merenderia_final.labels
(entity, fields)
values`
  defaultValues?.forEach(({ fields, entity }, index) => {
    let comma = ','
    if (index === defaultValues.length - 1) {
      comma = ';'
    }
    SQL_COMMAND += `('${entity}', '${JSON.stringify(fields)}')${comma} \n`
  })

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContent className="form">
            <div className="row mb-5 d-flex justify-content-between">
              {entitiesColumns && (
                <Select
                  name="entity"
                  label="Entidade"
                  className="col-md-3"
                  onChange={event => {
                    const actualEntity = entitiesColumns.find(
                      ({ entity }) => entity === event.target.value
                    )
                    setActualEntityColumn(actualEntity)
                  }}
                  options={entitiesColumns.map(({ entity }) => ({
                    name: entity,
                    value: entity
                  }))}
                  value={actualEntityColumn?.entity}
                  controlled
                  blank
                />
              )}
              <div className="col-md-3 d-flex align-items-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    navigator.clipboard.writeText(SQL_COMMAND)
                  }}
                >
                  Copiar comando SQL
                </button>
              </div>
            </div>
            <h3
              className="col-form-label fw-bold fs-6 bg-secondary"
              style={{ padding: '0.7rem 1rem', margin: '0 -1rem' }}
            >
              Campos
            </h3>
            <div className="row mt-2">
              {actualEntityColumn?.fields?.map((field: string) => {
                const actualLabels = defaultValues.find(
                  ({ entity }) => entity === actualEntityColumn.entity
                )
                let defaultValue
                if (actualLabels) {
                  defaultValue = actualLabels.fields[field]
                }
                if (
                  Object.prototype.hasOwnProperty.call(DEFAULT_VALUES, field)
                ) {
                  defaultValue = DEFAULT_VALUES[field]
                }
                return (
                  <Input
                    name={field}
                    label={field}
                    value={defaultValue}
                    onChange={event => {
                      setDefaultValues(old => {
                        const newDefaultValues = [...old]
                        let actualLabelsIndex = newDefaultValues.findIndex(
                          ({ entity }) => entity === actualEntityColumn.entity
                        )
                        if (actualLabelsIndex === -1) {
                          actualLabelsIndex = newDefaultValues.length
                          newDefaultValues[actualLabelsIndex] = {
                            fields: '',
                            entity: actualLabels.entity
                          }
                        }
                        newDefaultValues[actualLabelsIndex].fields[field] =
                          event.target.value

                        return newDefaultValues
                      })
                    }}
                    className="col-md-3"
                    key={field}
                    controlled
                  />
                )
              })}
            </div>
          </FormContent>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
