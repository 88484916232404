import ContractsCreate from '../../pages/Commercial/Contracts/Create'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'
import ContractsView from '../../pages/Commercial/Contracts/View'
import ContractList from 'pages/Commercial/Contracts/List'

export const ContractsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/contracts" component={ContractList} exact />
    <Route
      path="/commercial/contracts/students/:id/create"
      component={ContractsCreate}
      exact
    />
    <Route
      path="/commercial/contracts/view/:id"
      component={ContractsView}
      exact
    />
  </CustomSwitch>
)
