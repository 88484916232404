import React, { createContext, useCallback, useState, useContext } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import api from '../services/api'
import { Menus } from './menus'

interface IUser {
  id: string
  name: string
  username: string
  password: string
  avatar_url: string
  role_id: number
}

interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  type: string
}

interface AuthState {
  token: string
  user: IUser
  menus: Menu[]
}

interface SingInCredentials {
  email: string
  password: string
}

interface AuthContextData {
  user: IUser
  menus: Menu[]
  backupHistory: string
  signIn(credentials: SingInCredentials): Promise<void>
  signOut(): void
  userLogged(): boolean
  updateUser(): void
  setHistory(string: string): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [backupHistory, setBackupHistory] = useState('')
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Merenderia:token')
    const user = localStorage.getItem('@Merenderia:client')
    const menus = localStorage.getItem('@Merenderia:menus')

    if (token && user && menus) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return {
        token,
        user: JSON.parse(user),
        menus: Menus
      }
    }
    return {} as AuthState
  })

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessionsClient', {
      email,
      password
    })

    const { token, client } = response.data
    const menus = Menus
    localStorage.setItem('@Merenderia:token', token)
    localStorage.setItem('@Merenderia:client', JSON.stringify(client))
    localStorage.setItem('@Merenderia:menus', JSON.stringify(menus))

    api.defaults.headers.authorization = `Bearer ${token}`

    setData({ token, user: client, menus })
  }, [])

  const setHistory = useCallback((history: string) => {
    setBackupHistory(history)
  }, [])

  const userLogged = useCallback(() => {
    const token = localStorage.getItem('@Merenderia:token')
    if (token) {
      const decode = jwtDecode<JwtPayload>(token)
      const currentDate = Date.now().valueOf() / 1000
      if (decode.exp < currentDate) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [])

  const updateUser = useCallback(async () => {
    const user = await (await api.get('users/profile/show')).data

    setData({ token: data.token, user, menus: data.menus })

    localStorage.setItem('@Merenderia:client', JSON.stringify(user))
  }, [data.menus, data.token])

  const signOut = useCallback(() => {
    localStorage.removeItem('@Merenderia:token')
    localStorage.removeItem('@Merenderia:client')
    localStorage.removeItem('@Merenderia:menus')

    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        setHistory,
        backupHistory,
        user: data.user,
        menus: data.menus,
        signIn,
        signOut,
        userLogged,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
