export const sortWeek = (weekDays: string[]) => {
  if (!weekDays || weekDays.length === 0) return
  const arr = ['', '', '', '', '', '', '']
  weekDays.forEach(day => {
    if (day === 'Segunda-feira') arr[0] = 'Segunda-feira'
    if (day === 'Terça-feira') arr[1] = 'Terça-feira'
    if (day === 'Quarta-feira') arr[2] = 'Quarta-feira'
    if (day === 'Quinta-feira') arr[3] = 'Quinta-feira'
    if (day === 'Sexta-feira') arr[4] = 'Sexta-feira'
    if (day === 'Sábado') arr[5] = 'Sábado'
  })
  return arr.filter(str => str !== '')
}
