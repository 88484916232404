import List from '../../pages/Menus/List'
import Update from '../../pages/Menus/Update'
import Create from '../../pages/Menus/Create'
import View from '../../pages/Menus/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const MenusRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/menus" exact component={List} />
    <Route path="/menus/update/:id" component={Update} exact />
    <Route path="/menus/create" component={Create} exact />
    <Route path="/menus/view/:id" component={View} exact />
  </CustomSwitch>
)
