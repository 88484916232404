import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { ListTitle } from './styles'
import TableHeader from '../components/Header'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Search from '../components/Search'
import convertDateToISO from '../../../../utlis/convertDateToISO'

interface Permission {
  id: number
  name: string
}

interface PermissionAssociatedData {
  id: number
  permission: Permission
  routesAssociated: Permission[]
  created_at: string
  updated_at: string
}

const PermissionsAssociatedView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [permissionAssociated, setPermissionAssociated] =
    useState<PermissionAssociatedData | null>(null)
  const [subPermissionsSearch, setSubPermissionsSearch] = useState<
    Permission[]
  >([])
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setPermissionAssociated(data)
        setSubPermissionsSearch(data.routesAssociated)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Grupo de usuário',
          description:
            'Houve um error ao carregar o grupo, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  const getSearched = (value: string, searchData: Permission[]) => {
    const convertValue = value.toLowerCase()
    const filteritems = searchData.filter(item => {
      const convertName = item.name.toLowerCase()
      const convertId = item.id.toString()
      if (
        convertName.includes(convertValue) ||
        convertId.includes(convertValue)
      ) {
        return true
      }
      return false
    })
    return filteritems
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string, searchData: Permission[]) => {
    if (value.length === 0) {
      setSubPermissionsSearch(searchData)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(
      () => setSubPermissionsSearch(getSearched(value, searchData)),
      500
    )
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{permissionAssociated?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{permissionAssociated?.permission?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{permissionAssociated?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{permissionAssociated?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="page-content-inner">
            <div className="mt-content-body">
              <div>
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    paddingBottom: 10
                  }}
                >
                  <div className="col-md-3">
                    <ListTitle>
                      <p>Rotas Auxiliares</p>
                    </ListTitle>
                  </div>
                  <div className="col-md-3" style={{ margin: '0 auto' }}></div>
                  <div
                    className="col-md-3"
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <div className="">
                      <div className="dataTables_filter">
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <p style={{ marginRight: '15px' }}>Pesquisar</p>
                          <Search
                            onSearch={value =>
                              onSearchItem(
                                value,
                                permissionAssociated?.routesAssociated
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ marginBottom: 50 }} />
                <div className="table-scrollable">
                  <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                    <thead>
                      <tr>
                        <TableHeader
                          headers={[
                            { name: 'Cod.', field: 'id', sortable: true },
                            { name: 'Nome', field: 'name', sortable: true }
                          ]}
                          onSorting={(field, order, type) => {
                            const itemSorted = subPermissionsSearch.sort(
                              (a: any, b: any) => {
                                const fields = field.split('.')
                                let currentFieldA
                                let currentFieldB
                                if (fields.length === 1) {
                                  currentFieldA = a[fields[0]]
                                  currentFieldB = b[fields[0]]
                                }
                                if (fields.length === 2) {
                                  currentFieldA = a[fields[0]]?.[fields[1]]
                                  currentFieldB = b[fields[0]]?.[fields[1]]
                                }
                                if (fields.length === 3) {
                                  currentFieldA =
                                    a[fields[0]]?.[fields[1]]?.[fields[2]]
                                  currentFieldB =
                                    b[fields[0]]?.[fields[1]]?.[fields[2]]
                                }
                                if (type === 'date') {
                                  return new Date(
                                    convertDateToISO(currentFieldA)
                                  ) >
                                    new Date(convertDateToISO(currentFieldB)) &&
                                    order === 'ASC'
                                    ? 1
                                    : -1
                                }
                                if (type === 'monetary') {
                                  return Number(
                                    currentFieldA.replaceAll(/[',','.']/gi, '')
                                  ) >
                                    Number(
                                      currentFieldB.replaceAll(
                                        /[',','.']/gi,
                                        ''
                                      )
                                    ) && order === 'ASC'
                                    ? 1
                                    : -1
                                }
                                return currentFieldA > currentFieldB &&
                                  order === 'ASC'
                                  ? 1
                                  : -1
                              }
                            )
                            setSubPermissionsSearch([...itemSorted])
                          }}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {subPermissionsSearch.length ? (
                        subPermissionsSearch?.map(subPermission => (
                          <tr key={Math.random()}>
                            <td>{subPermission.id}</td>
                            <td>{subPermission.name}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2}>Sem Registros</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${permissionAssociated?.permission?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(permissionAssociated?.id)
          )
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default PermissionsAssociatedView
