export default function convertDateToISO(
  date: string,
  hasTime?: boolean
): string {
  const [day, month, year] = date.split('/')
  if (hasTime) {
    return `${year}-${month}-${day} 00:00:00`
  }
  return `${year}-${month}-${day}`
}
