import styled from 'styled-components'

export const MainBody = styled.div`
  @media (width<990px) {
    padding-top: 0px !important;
  }
  .fc-dayGridWeek-button {
    color: transparent !important;
    background-color: transparent !important;
    visibility: hidden;
    ::after {
      content: 'Semana';
      visibility: visible;
      padding: 5px 10px;
      color: #000;
      background-color: #f5f8fa;
    }
  }
  .fc-dayGridMonth-button {
    color: transparent !important;
    background-color: transparent !important;
    visibility: hidden;
    left: -30px;
    ::after {
      content: 'Mês';
      visibility: visible;
      padding: 5px 10px;
      color: #000;
      background-color: #f5f8fa;
    }
  }
`
