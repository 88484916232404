/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import {
  apiGetContract,
  apiGetPdfContract,
  apiGetPdfFinancialMoviment
} from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { nameActions } from '../domain/info'

import ViewInfoLabel from 'components/ViewInfoLabel'
import { useUpdateDataTable } from 'hooks/dataTable'
import { makeStringWithAnd } from 'utlis/makeAStringWithAnd'
import { currencyMask, percentualMask } from 'utlis/mask'
import { sortWeek } from 'utlis/sortWeek'
import { toolsPrintPdf } from '../domain/tools/view'
import { LinkContainer, MenuTableRow } from './styles'

const ContractView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [contract, setContract] = useState<IContractData>(undefined)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadContract(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IContractData>(apiGetContract(id))
        const { data } = response
        setContract(data)
        disableLoading()
      } catch (err) {
        // const [, baseUrl, path] = location.pathname.split('/')
        // disableLoading()
        // addToast({
        //   type: 'error',
        //   title: 'Error ao carregar o contrato',
        //   description:
        //     'Houve um error ao carregar o contrato, tente novamente mais tarde!'
        // })
        // if (path.includes('view')) {
        //   history.push(`/${baseUrl}`)
        // } else {
        //   history.push(`/${baseUrl}/${path}`)
        // }
      }
    }
    loadContract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    location.pathname,
    updateDataTable
  ])

  const getPdfContract = () => {
    activeLoading()

    api
      .get(apiGetPdfContract(id.toString()), { responseType: 'blob' })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
  }

  const getPdfBoleto = (
    financialMovimentPaymentId: number,
    actualIndex: number
  ) => {
    activeLoading()
    api
      .get(apiGetPdfFinancialMoviment(financialMovimentPaymentId.toString()))
      .then(res => {
        // const base64String = new Blob([res.data.pdf], {
        //   type: 'application/pdf'
        // })
        // console.log(new Buffer(res.data.pdf, 'binary').toString('base64')))
        const linkSource = `data:application/pdf;base64,${res.data.pdf}`
        // const blob = new Blob([linkSource], { type: 'application/pdf'})
        // window.open(URL.createObjectURL(blob))
        // window.open(`data:application/pdf;base64,${res.data.pdf}`)
        // const obj = document.createElement('object')
        // obj.style.width = '100%'
        // obj.style.height = '842pt'
        // obj.type = 'application/pdf'
        // obj.data = linkSource
        // document.body.appendChild(obj)
        const financialMovimentPayament =
          contract.financial_moviments[actualIndex].financialMovimentsPayments
        const downloadLink = document.createElement('a')
        const fileName = `Cliente - ${contract.client.name} Numero -${
          financialMovimentPayament[financialMovimentPayament.length - 1]
            .document_number
        }.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName

        // window.open(linkSource)
        downloadLink.click()

        disableLoading()
      })
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[toolsPrintPdf(id, getPdfContract)]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={contract?.client.name}
              label="Cliente"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.student.name}
              label="Estudante"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.institution.company_social_name}
              label="Instituição"
            />
          </div>
          <div className="separator my-3" />

          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={contract?.contract_plans[0]?.periodicity_payament}
              label="Periodicidade"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.form_payament}
              label="Forma de pagamento"
            />
          </div>
          <div className="separator my-3" />
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={currencyMask(contract?.value_without_discount)}
              label="Valor dos planos"
            />
            {contract?.discount !== '0.00' && (
              <>
                <ViewInfoLabel
                  col={3}
                  description={percentualMask(contract?.discount)}
                  label="Percentual do desconto"
                />
                <ViewInfoLabel
                  col={3}
                  description={currencyMask(
                    (+contract?.discount * contract?.value_without_discount) /
                      100
                  )}
                  label="Valor do desconto"
                />
              </>
            )}
            <ViewInfoLabel
              col={3}
              description={currencyMask(contract?.value_payament)}
              label="Valor total"
            />
          </div>
          <div className="separator my-3" />

          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={contract?.situation}
              label="Situação"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.created_at}
              label="Cadastrado em"
            />
          </div>

          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab title="Cardápios" key={0}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Cardápio</th>
                            <th>Frequência</th>
                            <th>Dias contratados</th>
                            <th>Periodicidade</th>
                            <th>Valor sem desconto</th>
                            <th>Desconto</th>
                            <th>Valor final</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.contract_plans?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.contract_plans?.map(contractPlan => {
                            return (
                              <MenuTableRow key={contractPlan.id}>
                                <td>
                                  {
                                    contractPlan.plan_frequency.menu_food
                                      ?.menu_food_type?.name
                                  }
                                </td>
                                <td>{contractPlan.plan_frequency.frequency}</td>
                                <td>
                                  {makeStringWithAnd(
                                    sortWeek(
                                      JSON.parse(
                                        contractPlan?.frequency_days_of_week
                                      )?.frequency_days_of_week
                                    )
                                  )}
                                </td>
                                <td>
                                  {
                                    JSON.parse(
                                      contractPlan.plan_frequency.payament_form
                                    ).periodicity
                                  }
                                </td>
                                <td>
                                  {currencyMask(
                                    JSON.parse(
                                      contractPlan.plan_frequency.payament_form
                                    ).value
                                  )}
                                </td>
                                <td>{percentualMask(contract?.discount)}</td>
                                <td>
                                  {currencyMask(
                                    contractPlan.value_with_discount -
                                      contractPlan.value_with_discount *
                                        (Number(contract?.discount) / 100)
                                  )}
                                </td>
                                <td></td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab title="Movimentações financeiras" key={1}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Data de vencimento</th>
                            <th>Forma de pagamento</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th style={{ textAlign: 'center' }}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.financial_moviments?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.financial_moviments?.map(
                            (financial_moviment, actualIndex) => {
                              const financialMovimentStatus =
                                financial_moviment.financialMovimentsPayments[0]
                                  ?.situation
                              return (
                                <MenuTableRow key={financial_moviment.id}>
                                  <td>
                                    {financial_moviment.due_date.slice(0, 10)}
                                  </td>
                                  <td>{contract.form_payament}</td>
                                  <td>
                                    {currencyMask(financial_moviment.value)}
                                  </td>
                                  <td>{financialMovimentStatus}</td>
                                  <td>
                                    {financialMovimentStatus.toLowerCase() !==
                                      'pago' && (
                                      <LinkContainer className="gap-2">
                                        <div>
                                          {financial_moviment.financialMovimentsPayments && (
                                            <span
                                              className="fa fa-print"
                                              style={{
                                                border: 'none',
                                                color: '#009EF7'
                                              }}
                                              onClick={() =>
                                                getPdfBoleto(
                                                  financial_moviment
                                                    .financialMovimentsPayments[
                                                    financial_moviment
                                                      .financialMovimentsPayments
                                                      .length - 1
                                                  ]?.id,
                                                  actualIndex
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </LinkContainer>
                                    )}
                                  </td>
                                </MenuTableRow>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ContractView
