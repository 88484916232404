type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  back: TypeActionNameWithTo
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  print: TypeActionNameWithTo
}

const nameEntity = 'Contract'
const nameSource = 'contracts'
const namePageTitle = 'Contratos'

const nameActions: PortletTitleCrudType = {
  back: {
    name: 'Retornar',
    to: '#!',
    icon: 'fa fa-arrow-left'
  },
  create: {
    name: 'Adicionar',
    to: '/commercial/contracts/students/:id/create',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/contracts/view/:id',
    icon: 'fa fa-list'
  },
  print: {
    name: 'Imprimir',
    to: '/commercial/contracts/view/:id',
    icon: 'fa fa-print'
  }
}

export { nameEntity, nameSource, namePageTitle, nameActions }
