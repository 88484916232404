import PrivateRoutes from '../Route'
import List from '../../pages/Config/Roles/List'
import Update from '../../pages/Config/Roles/Update'
import Create from '../../pages/Config/Roles/Create'
import View from '../../pages/Config/Roles/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const RolesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/users/roles" exact component={List} />
    <PrivateRoutes path="/users/roles/update/:id" component={Update} exact />
    <PrivateRoutes path="/users/roles/create" component={Create} exact />
    <PrivateRoutes path="/users/roles/view/:id" component={View} exact />
  </CustomSwitch>
)
