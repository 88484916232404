import { Menus } from 'hooks/menus'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageTitle } from '../../assets/layout/core'
import { CardSimple } from '../../components/CardSimple'
import { useAuth } from 'hooks/auth'
import api from 'services/api'

type ListType = IContractPlanCreate & {
  student_name: string
}

export const Dashboard: React.FC = () => {
  const history = useHistory()
  const [contracts, setContracts] = useState<ListType[]>([])
  const { user } = useAuth()

  const getContracts = useCallback(async () => {
    const contractsListResponse = await api.get(
      `commercial/contracts/byClient/${user.id}`
    )
    const contracts = contractsListResponse.data
    const contractPlans: ListType[] = []
    contracts
      .filter((a: any) =>
        ['Ativo', 'Aguardando pagamento'].includes(a.situation)
      )
      .forEach((contract: any) => {
        if (contract.contract_plans) {
          contract.contract_plans.forEach((contractPlan: any) => {
            contractPlans.push({
              ...contractPlan
            })
          })
        }
      })
    setContracts(contractPlans)
  }, [user])

  useEffect(() => {
    getContracts()
  }, [getContracts])

  return (
    <div className="container-fluid">
      <PageTitle>Informações Gerenciais</PageTitle>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          onClick={() => {
            history.push(Menus[4].url)
          }}
        >
          <CardSimple
            number=""
            primaryColor="red"
            text={Menus[4].name}
            textColor="red"
            spanIcon={Menus[4].icon}
            style={{
              margin: '10px',
              width: '200px',
              cursor: 'pointer',
              color: '#666'
            }}
            footer={{
              link: '#',
              text: contracts.length.toString()
            }}
          ></CardSimple>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
