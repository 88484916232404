import { useCallback, useEffect, useState } from 'react'
import api from '../../../../../../services/api'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { cpfMask, phoneMask, zipCodeMask } from '../../../../../../utlis/mask'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { GENDERS, optionsStates } from 'commons/constants/commercial'
import axios, { AxiosError } from 'axios'
import { validate } from 'gerador-validador-cpf'
import moment from 'moment'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IClientData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormClient = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<IClientData>()
  const [address, setAddress] = useState<Address>()
  const [zipCode, setZipCode] = useState<string>()
  const [phone1, setPhone1] = useState<string>()
  const [phone2, setPhone2] = useState<string>()
  const [cpf, setCpf] = useState<string>()
  const [cpfError, setCpfError] = useState<{
    message?: string
    error: boolean
  }>({ message: '', error: false })
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [birthDate, setBirthDate] = useState<Date | string>()

  const checkCpf = (cpf: string) => {
    setCpf(cpfMask(cpf))
    if (cpf.length === 14) {
      if (validate(cpf)) {
        setCpfError({
          error: false,
          message: 'CPF válido'
        })
      } else {
        setCpfError({
          error: true,
          message: 'CPF inválido'
        })
      }
    }
  }

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      const date = moment(initialValues?.birth_date, 'DD/MM/YYYY').toDate()
      if (initialValues?.birth_date) {
        initialValues.birth_date = date
      }
      setDefaultValues({
        ...initialValues,
        phone: phoneMask(initialValues?.phone || ''),
        phone_2: phoneMask(initialValues?.phone_2 || '')
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()
  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          // Alguns CEPS não disponibilizam logradouro/bairro
          if (logradouro.length === 0) {
            setAddress({
              city: localidade,
              state: uf,
              ibge
            })
          } else {
            setAddress({
              city: localidade,
              district: bairro,
              street: logradouro,
              state: uf,
              ibge
            })
          }
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualmente!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const onSubmitForm = async (data: IClientData) => {
    if (cpfError.error) {
      window.scrollTo(0, 0)
      return
    }
    data = deleteEmptyFields(data)
    const id = initialValues?.idUpdate
    console.log(data)
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            setHasUpdated(true)

            addToast({
              type: 'success',
              title: 'Cliente criado',
              description: 'Cliente criado com sucesso'
            })
          } catch (error: any) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o cliente',
              description: error.response.data
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
            setHasUpdated(true)
          }
        } else {
          try {
            const dataCreate = {
              ...data,
              phone: data.phone?.replace(' ', ''),
              phone_2: data.phone_2?.replace(' ', ''),
              ibge: address?.ibge
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err) {
            if (axios.isAxiosError(err)) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description: err.response.data.message
              })
              disableLoading()
              updateDataTable()
            }
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            if (axios.isAxiosError(error)) {
              handleOnClose()
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description: error.response.data.message
              })
            }
          }
        } else {
          const dataUpdate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Cliente atualizado',
              description: 'Cliente alterado com sucesso'
            })
          } catch (error: any) {
            if (axios.isAxiosError(error)) {
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description: error.response.data.message
              })
            }
            history.push(nameActions.read.to)
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues,
        ...address
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Input
                name="name"
                className=" col-md-6"
                label="Nome"
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="identity_card"
                className=" col-md-2"
                label="RG"
                maxLength={14}
              />
              <Input
                name="cpf"
                className=" col-md-2"
                label="CPF"
                value={cpf}
                rules={{ required: true, position: 'left' }}
                maxLength={14}
                onChange={event => checkCpf(event.target.value)}
                hasError={{
                  message: cpfError.message,
                  error: cpfError.error
                }}
              />
              <DatePicker
                label="Data de nascimento"
                className="col-md-2"
                name="birth_date"
                rules={{ required: true }}
                value={birthDate as string}
                selected={
                  (birthDate as Date) ||
                  (defaultValues?.birth_date as Date) ||
                  undefined
                }
                maxDate={moment().subtract(18, 'y').toDate()}
                validateOfAge
                refuseDateAfterToday
                onChange={date => setBirthDate(date)}
                controlled
              />
            </div>
            <Select
              name="gender"
              className="col-md-2"
              label="Sexo"
              options={GENDERS}
              rules={{ required: true }}
              blank
            />
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="zip_code"
                className=" col-md-2"
                label="CEP"
                rules={{ required: true, position: 'left' }}
                value={zipCode}
                onChange={event => getDataCep(event.target.value)}
                maxLength={9}
              />
              <Input
                name="street"
                className=" col-md-5"
                label="Logradouro"
                value={address?.street}
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="number"
                className=" col-md-2"
                label="Número"
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="complement"
                className=" col-md-3"
                label="Complemento"
              />
            </div>
            <div className="row mb-5">
              <Input
                name="district"
                value={address?.district}
                className=" col-md-3"
                label="Bairro"
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="city"
                className=" col-md-3"
                label="Cidade"
                value={address?.city}
                rules={{ required: true, position: 'left' }}
              />
              <Select
                className="col-md-3"
                label="Estado"
                name="state"
                options={optionsStates}
                rules={{ required: true }}
                value={address?.state}
                defaultValue=""
                blank
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="phone"
                className=" col-md-3"
                label="Telefone 1"
                value={phone1}
                onChange={event => setPhone1(phoneMask(event.target.value))}
                rules={{
                  required: true,
                  position: 'left',
                  pattern: {
                    message: 'telefone',
                    value: /^.{14,}$/
                  }
                }}
                maxLength={15}
              />
              <Input
                name="phone_2"
                className=" col-md-3"
                label="Telefone 2"
                value={phone2}
                onChange={event => setPhone2(phoneMask(event.target.value))}
                rules={{ minLength: 13, position: 'left' }}
                maxLength={15}
              />
              <Input
                name="email"
                className=" col-md-3"
                label="E-mail"
                rules={{
                  required: true,
                  position: 'left',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido'
                  }
                }}
              />
              <Input
                name="email_2"
                className=" col-md-3"
                label="E-mail 2"
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido'
                  },
                  position: 'left'
                }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              {typeForm === 'update' && (
                <>
                  <Input
                    name="password"
                    className="col-md-3"
                    label="Nova senha"
                  />
                </>
              )}
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
