import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiDishesTypes, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import api from 'services/api'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IDishData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormDish = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<IDishData>()
  const [dishesTypes, setDishesTypes] = useState<IDishType[]>([])
  const [dishType, setDishType] = useState<number>()

  const [hasUpdated, setHasUpdated] = useState<boolean>(false)

  useEffect(() => {
    api
      .get(apiDishesTypes())
      .then(res =>
        setDishesTypes(
          res.data.sort((a: IDishType, b: IDishType) =>
            a.name.localeCompare(b.name)
          )
        )
      )
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os tipos de pratos',
          description:
            'Houve um erro ao carregar os tipos de pratos disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IDishData) => {
    const id = initialValues?.idUpdate
    data = deleteEmptyFields(data)
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            handleOnClose()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Item criado',
              description: 'Item criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o item',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            updateDataTable()
            handleOnClose()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }

            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response.data, 'here')
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            const res = await api.put(apiUpdate(String(id)), dataUpdate)
            setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Item atualizado',
              description: 'Item alterado com sucesso'
            })
          } catch (err: any) {
            history.push(nameActions.read.to)
            console.log(err.response.data, 'here')

            addToast({
              type: 'error',
              title: 'Erro ao atualizar o item',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              <Input
                name="name"
                className="col-md-4"
                rules={{ required: true }}
                label="Nome"
              />
              <Select
                className=" col-md-4"
                name="dish_type_id"
                label="Tipo"
                onChange={e => setDishType(+e.target.value)}
                options={
                  typeForm === 'update'
                    ? dishesTypes.map(dish_type => {
                        return {
                          name: dish_type.name,
                          value: dish_type.id
                        }
                      })
                    : dishesTypes
                        .filter(dish_type => dish_type.is_active)
                        .map(dish_type => {
                          return {
                            name: dish_type.name,
                            value: dish_type.id
                          }
                        })
                }
                blank
                value={dishType}
                rules={{ required: true }}
                errors={{ dish_type_id: !dishType }}
                defaultValue={''}
              />
              {typeForm === 'update' && (
                <Select
                  className=" col-md-4"
                  name="is_active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  defaultValue={'true'}
                  rules={{ required: true }}
                />
              )}
            </div>

            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                style={{ minHeight: 120 }}
                defaultValue=""
                rules={{ required: true, position: 'left' }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
