type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'MenuFood'
const nameSource = 'menu_foods'
const namePageTitle = 'Cardápios'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Cardápios',
  to: '/commercial/menuFoods',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/menuFoods/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/menuFoods/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/menuFoods/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

const situations = [
  {
    name: 'Sim',
    value: 'true'
  },
  {
    name: 'Não',
    value: 'false'
  }
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  situations
}
