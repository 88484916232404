import { ToolsContainerProps } from '../../../../../../../components/Container'
import { nameActions } from '../../info'
export const toolsViewCreate = (): ToolsContainerProps => ({
  name: 'Adicionar',
  to: nameActions.create.to,
  icon: nameActions.create.icon,
  color: 'btn-light-success'
})

export const toolsViewList = (): ToolsContainerProps => ({
  name: 'Listar',
  to: nameActions.read.to,
  icon: nameActions.read.icon
})

export const toolsViewUpdate = (id: string): ToolsContainerProps => ({
  name: 'Editar',
  to: `${nameActions.update.to}`,
  icon: nameActions.update.icon,
  color: 'btn-light-warning'
})

export const toolsViewDelete = (
  handleOnClick: (pamams: any) => void
): ToolsContainerProps => ({
  name: 'Remover',
  to: nameActions.delete.to,
  icon: nameActions.delete.icon,
  color: 'btn-light-danger',
  handleOnClick
})
