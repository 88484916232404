import { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewList, toolsViewUpdate } from '../domain/tools'
import ViewInfoLabel from 'components/ViewInfoLabel'

const StudentView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [student, setStudent] = useState<IStudent | null>(null)

  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadStudent(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IStudent>(apiList(id))
        const { data } = response
        setStudent(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o estudante',
          description:
            'Houve um error ao carregar o estudante, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadStudent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[toolsViewUpdate(String(id)), toolsViewList()]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel col={4} description={student?.name} label="Nome" />
            <ViewInfoLabel col={4} description={student?.cpf} label="CPF" />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={4}
              description={student?.institution?.company_social_name}
              label="Instituição"
            />
            <ViewInfoLabel
              col={4}
              description={
                typeof student?.birth_date === 'string' &&
                student?.birth_date.slice(0, 10)
              }
              label="Data de nascimento"
            />
            <ViewInfoLabel col={4} description={student?.gender} label="Sexo" />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={4}
              description={student?.serie_class?.name}
              label="Ano/Turma"
            />
            <ViewInfoLabel
              col={4}
              description={student?.school_period}
              label="Turno"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={4}
              description={student?.created_at}
              label="Cadastrado em"
            />
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${student?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(student?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default StudentView
