import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../assets/helpers'
import { useLayout } from '../../../assets/layout/core'
import { AsideDefault } from '../../../assets/layout/components/aside/AsideDefault'
import { Toolbar } from '../../../components/Toolbar'
import logo from '../../../assets/media/logos/logo-2.svg'
import logoNegativa from '../../../assets/media/logos/mainLogo.png'
import asideSvg from '../../../assets/media/icons/duotune/abstract/abs015.svg'

import Header from '../../../components/Header'
import { Messenger } from '../../../components/Messenger'
import { Activity } from '../../../components/Activity'
import { useAuth } from 'hooks/auth'
import { MainBody } from './styles'

const DefaultLayout: React.FC = ({ children }) => {
  const { config } = useLayout()
  const { user } = useAuth()
  const { aside } = config
  return (
    <div className="page d-flex flex-column-fluid flex-column">
      <AsideDefault />
      {aside.display && (
        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1 achar"
          title="Show aside menu"
          style={{ marginTop: '120px', padding: '0px 30px' }}
        >
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/dashboard" className="d-lg-none">
              <img alt="Logo" src={logoNegativa} className="h-50px" />
            </Link>
          </div>

          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_aside_mobile_toggle"
          >
            <KTSVG path={asideSvg} className="svg-icon-2x mt-1" />
          </div>
        </div>
      )}
      <MainBody
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <Header avatarURL={user?.avatar_url} />
        <div
          id="kt_content"
          className="content d-flex flex-column flex-column-fluid"
        >
          <Toolbar />
          <div className="post d-flex flex-column-fluid" id="kt_post">
            {children}
          </div>
        </div>
        <Activity />
        <Messenger />
      </MainBody>
    </div>
  )
}

export default DefaultLayout
