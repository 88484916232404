import styled from 'styled-components'

export const FormContent = styled.div<{ modal: boolean }>`
  padding: 1em;

  display: ${props => (props.modal ? 'block' : 'flex')};
  flex-direction: column;

  > div {
    display: ${props => (props.modal ? 'block' : 'grid')};
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
  }
`
