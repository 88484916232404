import styled from 'styled-components'
import Container from '../../../../../components/Container'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`

export const StyledLink = styled.a`
  color: #009ef7;
  cursor: pointer;

  &:hover {
    color: '0.2s ease, background-color 0.2s ease';
  }
`

export const ModContainer = styled(Container)`
  button.fc-button-primary {
    background: red !important;
    content: 'oisodoi';
  }
`
