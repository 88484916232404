import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import CustomDataTable from '../../../../../components/CustomDataTable'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { useAuth } from 'hooks/auth'

const StudentList = (): JSX.Element => {
  const [students, setStudents] = useState<IStudent[]>([])
  const { user } = useAuth()
  const getStudents = useCallback(async () => {
    const studentsListResponse = await api.get(
      `commercial/students/byClient/${user.id}`
    )
    setStudents(studentsListResponse.data)
  }, [user])

  useEffect(() => {
    getStudents()
  }, [getStudents])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <h1>Alunos</h1>
      <hr style={{ margin: '20px 0px' }} />
      <CustomDataTable
        customItems={students}
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/commercial/students/view/${item.id}`
          }
        ]}
      />
    </Container>
  )
}

export default StudentList
