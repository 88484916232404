export const headers = [
  {
    name: 'Instituição',
    field: 'institution.company_social_name',
    sortable: true
  },
  {
    name: 'Cliente',
    field: 'client.name',
    sortable: true
  },
  {
    name: 'Estudante',
    field: 'student.name',
    sortable: true
  },
  { name: 'Forma de pagamento', field: 'form_payament', sortable: true },
  { name: 'Valor Total', field: 'value_payament', sortable: true },
  { name: 'Situação', field: 'situation', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
