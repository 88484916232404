import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { SearchInput } from './styles'

interface TableSearchProps {
  onSearch(value: string): void
  isPageChanged: boolean
  setIsPageChanged: Dispatch<SetStateAction<boolean>>
  setSearch: Dispatch<SetStateAction<string>>
  search: string
}

const Search: React.FC<TableSearchProps> = ({
  onSearch,
  isPageChanged,
  setIsPageChanged,
  search,
  setSearch
}) => {
  const onInputChange = (value: string) => {
    setSearch(value)
    onSearch(value)
  }
  useEffect(() => {
    if (isPageChanged) {
      setSearch('')
      setIsPageChanged(old => !old)
    }
  }, [isPageChanged, setSearch, setIsPageChanged])
  return (
    <SearchInput
      id="search"
      type="text"
      className="form-control input-sm input-small input-inline"
      style={{ width: '240px' }}
      value={search}
      onChange={e => onInputChange(e.target.value)}
    />
  )
}

export default Search
