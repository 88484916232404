import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormMenuFood } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../../hooks/toast'
import { v4 as uuidv4 } from 'uuid'
import { IComposition, useMenuFoodContext } from '../contexts'

const MenuFoodUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [menuFood, setMenuFood] = useState<IMenuFoodData>()
  const { setComposition, insertDefaultInstitutions } = useMenuFoodContext()
  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get<IMenuFoodData>(apiList(id))
        const { data } = response
        const defaultComposition = data.menu_dishes.map(
          (menuDish): IComposition => {
            return {
              id: uuidv4(),
              dish_id: menuDish.dish_id,
              dish_amount: menuDish.dish_amount,
              dish_name: menuDish.dish.name,
              dish_type_id: menuDish.dish.dish_type_id,
              is_default_dish: menuDish.is_default_dish
            }
          }
        )
        if (defaultComposition.length > 0) {
          setComposition(defaultComposition)
        }
        insertDefaultInstitutions(data.menu_institutions)
        setMenuFood(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o cardápio',
          description:
            'Houve um error ao carregar o cardápio, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history])
  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormMenuFood
        typeForm="update"
        initialValues={{
          idUpdate: Number(menuFood?.id),
          ...menuFood
        }}
      />
    </Container>
  )
}

export default MenuFoodUpdate
