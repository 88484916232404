import styled from 'styled-components'

export const FilterContaier = styled.div`
  width: 100%;
  padding-left: 0;
  float: none;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
  }
  select {
    font-size: 13;
    padding: 5;
  }
  margin-bottom: 30px !important;
  footer {
    margin-bottom: 20px;
    align-self: flex-end;

    button {
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;

      background: #fff;
      border: solid 1px #ccc;
      font-size: 12px;
      transition: all 0.2s;

      &:hover {
        background: #f2f2f2;
      }
    }
  }
`
export const ListTitle = styled.div`
  p {
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #666;
  }

  hr {
    margin-bottom: 10px;
  }
`
export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th:last-child {
    text-align: center;
  }

  td,
  th,
  thead {
    padding: 8px 10px;
    border: 1px solid #e7ecf1;
  }
`
export const TableRow = styled.tr`
  background: #fbfcfd;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #666;

  > div {
    margin: 0px 0.4em;

    cursor: pointer;
  }
`
