import DataTable from '../../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import Form, { Select } from 'components/Form'
import { useToast } from 'hooks/toast'
import { useState, useEffect } from 'react'
import api from 'services/api'
import { apiListDishTypes } from '../../Register/domain/api'
import { useHistory, useLocation } from 'react-router-dom'
import { situations } from '../../Register/domain/info'

const DishesList = () => {
  const { addToast } = useToast()
  const [situation, setSituation] = useState<string>()
  const [dishType, setDishType] = useState<string>()
  const [dishTypes, setDishTypes] = useState<IDishType[]>([])
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()

  useEffect(() => {
    api
      .get(apiListDishTypes())
      .then(res => {
        const orderedData = res.data.sort((a: IDishType, b: IDishType) =>
          a.name.localeCompare(b.name)
        )
        setDishTypes(orderedData)
      })
      .catch(e => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os tipos de cardápio',
          description:
            'Houve um erro ao carregar os tipos de cardápio disponíveis no banco de dados.'
        })
      })
  }, [])

  const onSearch = async () => {
    const query = []
    if (dishType) {
      query.push(`dish_type_id=${dishType}`)
    }
    if (situation) {
      query.push(`is_active=${situation}`)
    }

    history.replace(`/commercial/dishes?${query.join('&')}`)
    if (situation || dishType) {
      let is_active
      if (situation === 'false') {
        is_active = false
      } else if (situation === 'true') {
        is_active = true
      }
      setParameters([
        {
          dish_type_id: dishType || undefined,
          is_active: is_active
        }
      ])
    } else {
      setParameters([])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const dishType = location.get('menu_food_type_id')
    const situation = location.get('is_active')
    let is_active
    if (situation === 'false') {
      is_active = false
    } else if (situation === 'true') {
      is_active = true
    }

    if (situation || dishType) {
      if (dishType) {
        setDishType(dishType)
      }
      setSituation(situation)
      setParameters([
        {
          menu_food_type_id: dishType || undefined,
          is_active: is_active
        }
      ])
    } else {
      setParameters([])
    }
  }, [])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <div>
        <Form onSubmit={onSearch} defaultValues={{}} className="col-md-12">
          <div className="col-md-6 d-flex">
            <div className="col-md-6 pe-8">
              <Select
                label="Tipo"
                name="type"
                options={dishTypes?.map(type => {
                  return {
                    name: type.name,
                    value: type.id
                  }
                })}
                onChange={e => setDishType(e.target.value)}
                value={dishType}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-6">
              <Select
                label="Ativo"
                name="is_active"
                options={situations}
                onChange={e => setSituation(e.target.value)}
                value={situation}
                controlled
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <footer className="col-md-6 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setSituation(undefined)
                setDishType(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </div>

      <div>
        <h3 className="h3 pt-6">Listagem</h3>
      </div>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        headers={headers}
        searchParameters={parameters}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: dish => `/commercial/dishes/view/${dish.id}`,
            style: { margin: '0 5px' }
          },
          {
            name: 'Editar',
            title: 'Editar',
            spanIcon: 'fa fa-edit',
            linkTo: dish => `/commercial/dishes/update/${dish.id}`
          }
        ]}
      />
    </Container>
  )
}

export default DishesList
