export const headers = [
  {
    name: 'Instituição',
    field: 'institution.company_social_name',
    sortable: true
  },
  { name: 'Aluno', field: 'name', sortable: false },
  { name: 'Ano/Turma', field: 'serie_class.name', sortable: false },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
