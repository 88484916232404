import { currencyMask } from './../../../utlis/mask'
import {
  convertValueMaskInNumberWithTwoZeroDigits,
  currencyToNumber
} from 'utlis/mask'

export const optionsStates = [
  {
    name: 'Acre',
    value: 'AC'
  },
  {
    name: 'Alagoas',
    value: 'AL'
  },
  {
    name: 'Amazonas',
    value: 'AM'
  },
  {
    name: 'Amapá',
    value: 'AP'
  },
  {
    name: 'Bahia',
    value: 'BA'
  },
  {
    name: 'Ceará',
    value: 'CE'
  },
  {
    name: 'Distrito Federal',
    value: 'DF'
  },
  {
    name: 'Espírito Santo',
    value: 'ES'
  },
  {
    name: 'Goiás',
    value: 'GO'
  },
  {
    name: 'Maranhão',
    value: 'MA'
  },
  {
    name: 'Minas Gerais',
    value: 'MG'
  },
  {
    name: 'Mato Grosso do Sul',
    value: 'MS'
  },
  {
    name: 'Mato Grosso',
    value: 'MT'
  },
  {
    name: 'Pará',
    value: 'PA'
  },
  {
    name: 'Paraíba',
    value: 'PB'
  },
  {
    name: 'Pernambuco',
    value: 'PE'
  },
  {
    name: 'Piauí',
    value: 'PI'
  },
  {
    name: 'Paraná',
    value: 'PR'
  },
  {
    name: 'Rio de Janeiro',
    value: 'RJ'
  },
  {
    name: 'Rio Grande do Norte',
    value: 'RN'
  },
  {
    name: 'Rondônia',
    value: 'RO'
  },
  {
    name: 'Roraima',
    value: 'RR'
  },
  {
    name: 'Rio Grande do Sul',
    value: 'RS'
  },
  {
    name: 'Santa Catarina',
    value: 'SC'
  },
  {
    name: 'Sergipe',
    value: 'SE'
  },
  {
    name: 'São Paulo',
    value: 'SP'
  },
  {
    name: 'Tocantins',
    value: 'TO'
  }
]

export const GENDERS = [
  {
    name: 'Masculino',
    value: 'Masculino'
  },
  {
    name: 'Feminino',
    value: 'Feminino'
  }
]

export const SCHOOL_PERIODS = [
  {
    name: 'Manha',
    value: 'Manha'
  },
  {
    name: 'Tarde',
    value: 'Tarde'
  },
  {
    name: 'Integral',
    value: 'Integral'
  }
]

export const PAYAMENT_FORM_PERIODICITIES = [
  'Mensal',
  'Trimestral',
  'Semestral',
  'Anual'
]

export const PLAN_FREQUENCIES = [
  {
    name: '1 vez por semana',
    value: '1 vez por semana'
  },
  {
    name: '2 vezes por semana',
    value: '2 vezes por semana'
  },
  {
    name: '3 vezes por semana',
    value: '3 vezes por semana'
  },
  {
    name: '4 vezes por semana',
    value: '4 vezes por semana'
  },
  {
    name: '5 vezes por semana',
    value: '5 vezes por semana'
  },
  {
    name: '6 vezes por semana',
    value: '6 vezes por semana'
  }
]

export const TYPE_PERSON: {
  [key: string]: {
    NAME: string
    ABBREVIATION: string
    DOCUMENT: string
    MASK: string
  }
} = {
  PF: {
    NAME: 'Pessoa Física',
    ABBREVIATION: 'PF',
    DOCUMENT: 'cpf',
    MASK: '999.999.999-99'
  },
  PJ: {
    NAME: 'Pessoa Jurídica',
    ABBREVIATION: 'PJ',
    DOCUMENT: 'cnpj',
    MASK: '99.999.999/9999-99'
  }
}

export const FORMS_PAYAMENT = ['Boleto', 'Cartão', 'Pix']

export const SITUATION_TYPES_CONTRACT = ['Aguardando pagamento', 'Pago']

export const OPTIONS_FORM_PAYAMENT = FORMS_PAYAMENT.map(option => ({
  name: option,
  value: option
}))

export const OPTIONS_FORM_BOLETO = (
  numberOfParcels: number,
  priceTotal: string
) => {
  const OPTIONS = []
  for (let i = 1; i <= numberOfParcels; i++) {
    const parcelPriceNumber = currencyToNumber(priceTotal) / i
    const parcelPrice = currencyMask(parcelPriceNumber)
    const standardOption = `${i} parcela${
      i === 1 ? '' : 's'
    } de ${parcelPrice} (${priceTotal} s/ juros)`
    OPTIONS.push({ name: standardOption, value: i })
  }
  return OPTIONS
}
