export const apiCreate = (): string => '/commercial/menuFoods/create'
export const apiList = (id?: string): string =>
  !id ? '/commercial/menufoods/' : `/commercial/menuFoods/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/menuFoods/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/menuFoods/delete/${id}`

export const apiListDishes = (): string => '/commercial/dishes'
export const apiListDishTypes = (): string => '/commercial/dishesTypes'

export const apiListInstitutions = (): string => '/commercial/institutions'

export const apiListMenuFoodTypes = (): string => '/commercial/menuFoodTypes'

export const apiListContractPlansClient = (): string =>
  '/commercial/contractPlans'
